import type { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AgencyLimit: { input: any; output: any; }
  AssetURL: { input: any; output: any; }
  CityLimit: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  EstablishmentLimit: { input: any; output: any; }
  Limit: { input: any; output: any; }
  Path: { input: any; output: any; }
  PhotoLimit: { input: any; output: any; }
  URL: { input: any; output: any; }
};

export type Agency = {
  __typename?: 'Agency';
  contactAddress?: Maybe<Scalars['String']['output']>;
  coverUrl?: Maybe<Scalars['AssetURL']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Unique Item categories */
  itemCategories: Array<Category>;
  items: Array<Item>;
  itemsCount: Scalars['Int']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  logoUrl?: Maybe<Scalars['AssetURL']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  path: Scalars['Path']['output'];
  phonesMasked: Array<Scalars['String']['output']>;
  schedule?: Maybe<Scalars['String']['output']>;
  viewsCount: Scalars['Int']['output'];
};


export type AgencyItemsArgs = {
  limit?: Scalars['Limit']['input'];
  sort?: AgencyItemSort;
};

export enum AgencyItemSort {
  Random = 'RANDOM'
}

export enum AgencySort {
  Random = 'RANDOM'
}

/** Autogenerated input type of AnalyticLogCreate */
export type AnalyticLogCreateInput = {
  eventType: AnalyticLogEventTypeEnum;
  phones?: InputMaybe<Array<Scalars['String']['input']>>;
  source: AnalyticLogSourceEnum;
  sourceLink?: InputMaybe<Scalars['String']['input']>;
  targetId: Scalars['ID']['input'];
  targetType: AnalyticLogTargetTypeEnum;
  triggerButton?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AnalyticLogCreate. */
export type AnalyticLogCreatePayload = {
  __typename?: 'AnalyticLogCreatePayload';
  errors: Array<UserError>;
};

export enum AnalyticLogEventTypeEnum {
  ItemHit = 'ITEM_HIT',
  ShowPhones = 'SHOW_PHONES'
}

export enum AnalyticLogSourceEnum {
  App = 'APP',
  Mobile = 'MOBILE'
}

export enum AnalyticLogTargetTypeEnum {
  Agency = 'AGENCY',
  Item = 'ITEM',
  Residence = 'RESIDENCE'
}

export type Apartment = Currency & {
  __typename?: 'Apartment';
  area: Scalars['Float']['output'];
  balconiesCount?: Maybe<Scalars['Int']['output']>;
  bathroomsCount?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mortgagePriceFrom?: Maybe<Scalars['Int']['output']>;
  photos?: Maybe<Array<Photo>>;
  priceFrom: Scalars['Int']['output'];
  repair?: Maybe<BuildingProperty>;
  rooms: Scalars['Int']['output'];
};


export type ApartmentPhotosArgs = {
  limit?: InputMaybe<Scalars['PhotoLimit']['input']>;
};

export type Area = {
  __typename?: 'Area';
  units?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type BaseCompany = {
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  targetType?: Maybe<CompanyTargetTypeEnum>;
};

export type Bookmark = {
  __typename?: 'Bookmark';
  id: Scalars['ID']['output'];
  item: Item;
  itemId: Scalars['Int']['output'];
};

/** Autogenerated return type of BookmarkCreate. */
export type BookmarkCreatePayload = {
  __typename?: 'BookmarkCreatePayload';
  bookmark?: Maybe<Bookmark>;
  errors: Array<UserError>;
};

/** Autogenerated return type of BookmarkDelete. */
export type BookmarkDeletePayload = {
  __typename?: 'BookmarkDeletePayload';
  bookmark?: Maybe<Bookmark>;
  errors: Array<UserError>;
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  name: Scalars['String']['output'];
  path?: Maybe<Scalars['Path']['output']>;
};

export type BuildingProperty = {
  __typename?: 'BuildingProperty';
  iconUrl?: Maybe<Scalars['AssetURL']['output']>;
  name: Scalars['String']['output'];
};

export type Business = Agency | Residence;

export type Card = {
  __typename?: 'Card';
  expiry?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['AssetURL']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  issuer?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pan?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};

/** Autogenerated return type of CardCreate. */
export type CardCreatePayload = {
  __typename?: 'CardCreatePayload';
  errors: Array<UserError>;
  /** Card input page URL to redirect cardholder */
  redirectUrl?: Maybe<Scalars['URL']['output']>;
  status: CardCreateStatusEnum;
};

export enum CardCreateStatusEnum {
  Failure = 'FAILURE',
  /** Follow to the URL provided */
  Redirect = 'REDIRECT',
  Success = 'SUCCESS'
}

/** Autogenerated input type of CardDelete */
export type CardDeleteInput = {
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CardDelete. */
export type CardDeletePayload = {
  __typename?: 'CardDeletePayload';
  cards: Array<Card>;
  errors: Array<UserError>;
};

/** Autogenerated input type of CardSetPrimary */
export type CardSetPrimaryInput = {
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CardSetPrimary. */
export type CardSetPrimaryPayload = {
  __typename?: 'CardSetPrimaryPayload';
  cards: Array<Card>;
  errors: Array<UserError>;
};

/** Autogenerated input type of CardUpdate */
export type CardUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CardUpdate. */
export type CardUpdatePayload = {
  __typename?: 'CardUpdatePayload';
  card?: Maybe<Card>;
  errors: Array<UserError>;
  status: CardUpdateStatusEnum;
};

export enum CardUpdateStatusEnum {
  /** No access to an user card */
  Forbidden = 'FORBIDDEN',
  /** User card data contains errors */
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  /** User card successfully updated */
  Success = 'SUCCESS'
}

export type Category = {
  __typename?: 'Category';
  areRoomsRequired: Scalars['Boolean']['output'];
  areaUnits: Scalars['String']['output'];
  /** Array of immediate children (just those in the next level) */
  children: Array<Category>;
  hasBuildingType: Scalars['Boolean']['output'];
  hasFloor: Scalars['Boolean']['output'];
  hasLandArea: Scalars['Boolean']['output'];
  hasRepair: Scalars['Boolean']['output'];
  hasRooms: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  photosRequired: Scalars['Int']['output'];
  pluralName: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CategoryFilter = {
  scope?: CategoryScopeEnum;
};

export enum CategoryScopeEnum {
  Commercial = 'COMMERCIAL',
  Lot = 'LOT',
  NotCommercial = 'NOT_COMMERCIAL',
  NotLot = 'NOT_LOT',
  Roots = 'ROOTS',
  WithoutParent = 'WITHOUT_PARENT',
  WithoutRooms = 'WITHOUT_ROOMS',
  WithBuildingType = 'WITH_BUILDING_TYPE',
  WithFloor = 'WITH_FLOOR',
  WithLandArea = 'WITH_LAND_AREA',
  WithParent = 'WITH_PARENT',
  WithRooms = 'WITH_ROOMS'
}

export type City = {
  __typename?: 'City';
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  locations?: Maybe<Array<Location>>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path: Scalars['Path']['output'];
  slug: Scalars['String']['output'];
};

export type CityFilter = {
  scope?: CityScopeEnum;
};

export enum CityScopeEnum {
  All = 'ALL',
  Popular = 'POPULAR',
  Unpopular = 'UNPOPULAR'
}

export type Company = BaseCompany & {
  __typename?: 'Company';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  targetType?: Maybe<CompanyTargetTypeEnum>;
};

export type CompanyBalance = {
  __typename?: 'CompanyBalance';
  item: CompanyItemBalance;
  personalAccount: CompanyPersonalAccountBalance;
};

export type CompanyItemBalance = {
  __typename?: 'CompanyItemBalance';
  free: Scalars['Int']['output'];
  nextFreeAt?: Maybe<Scalars['DateTime']['output']>;
  paid: Scalars['Int']['output'];
};

export type CompanyNotification = {
  __typename?: 'CompanyNotification';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CompanyPersonalAccountBalance = {
  __typename?: 'CompanyPersonalAccountBalance';
  bonus: Scalars['Float']['output'];
  cash: Scalars['Float']['output'];
  pack: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type CompanyProfile = BaseCompany & {
  __typename?: 'CompanyProfile';
  alternativePhone?: Maybe<Scalars['String']['output']>;
  balance: CompanyBalance;
  /** Business that stands behind */
  business?: Maybe<Business>;
  contactName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hasUnpaidInvoice: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Does a profile belong to a business? */
  isBusiness: Scalars['Boolean']['output'];
  items: ItemConnection;
  name?: Maybe<Scalars['String']['output']>;
  notifications: Array<CompanyNotification>;
  /** @deprecated Use `balance` instead */
  paidItemsBalance: Scalars['Int']['output'];
  paidServices: Array<PaidService>;
  phone?: Maybe<Scalars['String']['output']>;
  targetType?: Maybe<CompanyTargetTypeEnum>;
};


export type CompanyProfileItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<ProfileItemTabStatesEnum>;
};

export enum CompanyTargetTypeEnum {
  Agency = 'AGENCY',
  Residence = 'RESIDENCE'
}

export type Currency = {
  currency: Scalars['String']['output'];
};

/** Location displayed to the end user */
export type DisplayedLocation = City | Location;

/** Different representation of the phone */
export type DisplayedPhone = MaskedPhone | Phone;

export type EsArea = {
  __typename?: 'ESArea';
  units?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Item primarily in lists */
export type EsItem = {
  __typename?: 'ESItem';
  area: EsArea;
  city: City;
  company?: Maybe<Company>;
  featured: Scalars['Boolean']['output'];
  floor?: Maybe<Scalars['Int']['output']>;
  floors?: Maybe<Scalars['Int']['output']>;
  hasBillOfSale?: Maybe<Scalars['Boolean']['output']>;
  hasMortgage?: Maybe<Scalars['Boolean']['output']>;
  hasRepair?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isBusiness: Scalars['Boolean']['output'];
  leased?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Location>;
  paidDaily?: Maybe<Scalars['Boolean']['output']>;
  path: Scalars['Path']['output'];
  photos: Array<EsPhoto>;
  photosCount: Scalars['Int']['output'];
  price: EsPrice;
  rooms?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vipped: Scalars['Boolean']['output'];
};


/** Item primarily in lists */
export type EsItemPhotosArgs = {
  limit?: InputMaybe<Scalars['PhotoLimit']['input']>;
};

/** The connection type for ESItem. */
export type EsItemConnection = {
  __typename?: 'ESItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EsItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EsItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EsItemEdge = {
  __typename?: 'ESItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EsItem>;
};

export type EsPhoto = {
  __typename?: 'ESPhoto';
  f460x345?: Maybe<Scalars['AssetURL']['output']>;
  large?: Maybe<Scalars['AssetURL']['output']>;
  thumbnail?: Maybe<Scalars['AssetURL']['output']>;
};

export type EsPrice = Currency & {
  __typename?: 'ESPrice';
  currency: Scalars['String']['output'];
  value?: Maybe<Scalars['Int']['output']>;
};

/** Original and pending data for Item edit */
export type Edit = {
  __typename?: 'Edit';
  /** Area of the housing/building */
  area?: Maybe<Area>;
  contactName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['Int']['output']>;
  floors?: Maybe<Scalars['Int']['output']>;
  hasBillOfSale?: Maybe<Scalars['Boolean']['output']>;
  hasMortgage?: Maybe<Scalars['Boolean']['output']>;
  hasRepair?: Maybe<Scalars['Boolean']['output']>;
  /** Area of land */
  landArea?: Maybe<Area>;
  paidDaily?: Maybe<Scalars['Boolean']['output']>;
  photos?: Maybe<Array<Photo>>;
  price?: Maybe<Price>;
  rooms?: Maybe<Scalars['Int']['output']>;
};


/** Original and pending data for Item edit */
export type EditPhotosArgs = {
  limit?: InputMaybe<Scalars['PhotoLimit']['input']>;
};

export type EmailAndPasswordInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Flash message from Rails */
export type FlashMessage = {
  __typename?: 'FlashMessage';
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  additionalConditions?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Float']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  package?: Maybe<Package>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  paidServices: Array<PaidService>;
  /** Successful payment */
  payment?: Maybe<Payment>;
  printUrl?: Maybe<Scalars['URL']['output']>;
  startsAt: Scalars['Date']['output'];
  status: InvoiceStatusEnum;
  vatAmount: Scalars['Float']['output'];
};

/** The connection type for Invoice. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
};

export enum InvoiceCreateForPackageActivationTypeEnum {
  /** At the end of the current package */
  Postpone = 'POSTPONE',
  Today = 'TODAY'
}

/** Autogenerated input type of InvoiceCreateForPackage */
export type InvoiceCreateForPackageInput = {
  activationType: InvoiceCreateForPackageActivationTypeEnum;
  name: Scalars['String']['input'];
  period: Scalars['Int']['input'];
};

/** Autogenerated return type of InvoiceCreateForPackage. */
export type InvoiceCreateForPackagePayload = {
  __typename?: 'InvoiceCreateForPackagePayload';
  errors: Array<UserError>;
  invoice?: Maybe<Invoice>;
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

export enum InvoiceScopeEnum {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Revoked = 'REVOKED',
  Unpaid = 'UNPAID'
}

export enum InvoiceStatusEnum {
  Canceled = 'CANCELED',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Revoked = 'REVOKED',
  Unpaid = 'UNPAID'
}

/** The main object in project */
export type Item = {
  __typename?: 'Item';
  /** Actions on the item available to the current user */
  actions: Array<ItemUserActionsEnum>;
  address?: Maybe<Scalars['String']['output']>;
  /** Area of the housing/building */
  area: Area;
  breadcrumbs?: Maybe<Array<Breadcrumb>>;
  buildingTypeName?: Maybe<Scalars['String']['output']>;
  /** Active business that stands behind */
  business?: Maybe<Business>;
  category: Category;
  city: City;
  company?: Maybe<Company>;
  contactName?: Maybe<Scalars['String']['output']>;
  /** Ownership */
  contactTypeName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Location displayed to the end user */
  displayedLocation?: Maybe<DisplayedLocation>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use `isFeatured` instead */
  featured: Scalars['Boolean']['output'];
  floor?: Maybe<Scalars['Int']['output']>;
  floors?: Maybe<Scalars['Int']['output']>;
  hasBillOfSale?: Maybe<Scalars['Boolean']['output']>;
  hasMortgage?: Maybe<Scalars['Boolean']['output']>;
  hasRepair?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** Is it bookmarked by the current user? To use cache for item list consider using `bookmarks` query. */
  isBookmarked: Scalars['Boolean']['output'];
  isExpiredManually?: Maybe<Scalars['Boolean']['output']>;
  isFeatured: Scalars['Boolean']['output'];
  /** Should a loan from Kapital Bank be calculated? */
  isKapitalbankApplicable: Scalars['Boolean']['output'];
  isLeased: Scalars['Boolean']['output'];
  /** Is it owned by the current user? */
  isMine: Scalars['Boolean']['output'];
  isReported: Scalars['Boolean']['output'];
  isVipped: Scalars['Boolean']['output'];
  /** Area of land */
  landArea?: Maybe<Area>;
  latitude?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use `isLeased` instead */
  leased?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Location>;
  longitude?: Maybe<Scalars['Float']['output']>;
  metaTags: Array<MetaTag>;
  nearestLocations?: Maybe<Array<NearestLocation>>;
  paidDaily?: Maybe<Scalars['Boolean']['output']>;
  paidServices?: Maybe<Array<PaidService>>;
  path: Scalars['Path']['output'];
  /** Returns phones depending on current user. Unmasked for owner and masked for someone else. */
  phones: Array<DisplayedPhone>;
  photos?: Maybe<Array<Photo>>;
  photosCount: Scalars['Int']['output'];
  price: Price;
  rejectReason?: Maybe<Scalars['String']['output']>;
  rooms?: Maybe<Scalars['Int']['output']>;
  state: ItemStatusEnum;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  views: Scalars['Int']['output'];
  /** @deprecated Use `isVipped` instead */
  vipped: Scalars['Boolean']['output'];
};


/** The main object in project */
export type ItemPhotosArgs = {
  limit?: InputMaybe<Scalars['PhotoLimit']['input']>;
};

export type ItemBalanceTransaction = {
  __typename?: 'ItemBalanceTransaction';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  source?: Maybe<ItemBalanceTransactionSource>;
  total: Scalars['Int']['output'];
};

/** The connection type for ItemBalanceTransaction. */
export type ItemBalanceTransactionConnection = {
  __typename?: 'ItemBalanceTransactionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ItemBalanceTransactionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ItemBalanceTransaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ItemBalanceTransactionEdge = {
  __typename?: 'ItemBalanceTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ItemBalanceTransaction>;
};

export type ItemBalanceTransactionSource = Item | Payment;

export enum ItemBuildingTypeEnum {
  Apartment = 'APARTMENT',
  BusinessCenter = 'BUSINESS_CENTER',
  Villa = 'VILLA'
}

/** The connection type for Item. */
export type ItemConnection = {
  __typename?: 'ItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Item>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
};

export enum ItemConnectionSort {
  AreaAsc = 'AREA_ASC',
  BumpedAtDesc = 'BUMPED_AT_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC'
}

export type ItemCreateAreaInput = {
  /** Land area */
  land?: InputMaybe<Scalars['Float']['input']>;
  /** Object area */
  value: Scalars['Float']['input'];
};

export enum ItemCreateBuildingTypeEnum {
  Apartment = 'APARTMENT',
  BusinessCenter = 'BUSINESS_CENTER',
  Villa = 'VILLA'
}

export type ItemCreateContactInput = {
  /** Only matters when current profile email is empty */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Only matters when current profile name is empty */
  name?: InputMaybe<Scalars['String']['input']>;
  type: ItemCreateContactTypeEnum;
};

export enum ItemCreateContactTypeEnum {
  Agent = 'AGENT',
  Owner = 'OWNER'
}

export type ItemCreateFloorInput = {
  /** Total floors */
  total: Scalars['Float']['input'];
  /** Object floor */
  value: Scalars['Float']['input'];
};

/** Autogenerated input type of ItemCreate */
export type ItemCreateInput = {
  area: ItemCreateAreaInput;
  buildingType?: InputMaybe<ItemCreateBuildingTypeEnum>;
  categoryId: Scalars['ID']['input'];
  contact: ItemCreateContactInput;
  description?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<ItemCreateFloorInput>;
  hasBillOfSale?: InputMaybe<Scalars['Boolean']['input']>;
  hasMortgage?: InputMaybe<Scalars['Boolean']['input']>;
  hasRepair?: InputMaybe<Scalars['Boolean']['input']>;
  isLeased: Scalars['Boolean']['input'];
  location: ItemCreateLocationInput;
  paidDaily?: InputMaybe<Scalars['Boolean']['input']>;
  photoIds: Array<Scalars['ID']['input']>;
  price: Scalars['Int']['input'];
  rooms?: InputMaybe<Scalars['Int']['input']>;
};

export type ItemCreateLocationInput = {
  address: Scalars['String']['input'];
  cityId: Scalars['ID']['input'];
  landmarkIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  regionId?: InputMaybe<Scalars['ID']['input']>;
  townshipId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of ItemCreate. */
export type ItemCreatePayload = {
  __typename?: 'ItemCreatePayload';
  errors: Array<UserError>;
  item?: Maybe<Item>;
  status: ItemCreateStatusEnum;
};

export enum ItemCreateStatusEnum {
  /** General error */
  Failure = 'FAILURE',
  /** Item data contains errors */
  Invalid = 'INVALID',
  /** Item successfully created */
  Success = 'SUCCESS',
  /** User needs to verify the phone */
  Unverified = 'UNVERIFIED'
}

/** An edge in a connection. */
export type ItemEdge = {
  __typename?: 'ItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Item>;
};

export type ItemEdit = {
  __typename?: 'ItemEdit';
  edit: Edit;
  /** Original item */
  item: Item;
};

export type ItemFilter = {
  agencyId?: InputMaybe<Scalars['ID']['input']>;
  areaFrom?: InputMaybe<Scalars['Int']['input']>;
  areaTo?: InputMaybe<Scalars['Int']['input']>;
  buildingType?: InputMaybe<Array<ItemBuildingTypeEnum>>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  cityId?: InputMaybe<Scalars['ID']['input']>;
  cityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  floorFirst?: InputMaybe<Scalars['Boolean']['input']>;
  floorFrom?: InputMaybe<Scalars['Int']['input']>;
  floorLast?: InputMaybe<Scalars['Boolean']['input']>;
  floorTo?: InputMaybe<Scalars['Int']['input']>;
  hasBillOfSale?: InputMaybe<Scalars['Boolean']['input']>;
  hasMortgage?: InputMaybe<Scalars['Boolean']['input']>;
  hasRepair?: InputMaybe<Scalars['Boolean']['input']>;
  landAreaFrom?: InputMaybe<Scalars['Int']['input']>;
  landAreaTo?: InputMaybe<Scalars['Int']['input']>;
  leased?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Exclude Item with ID from results */
  notId?: InputMaybe<Scalars['ID']['input']>;
  onlyResidences?: InputMaybe<Scalars['Boolean']['input']>;
  paidDaily?: InputMaybe<Scalars['Boolean']['input']>;
  priceFrom?: InputMaybe<Scalars['Int']['input']>;
  priceTo?: InputMaybe<Scalars['Int']['input']>;
  residenceId?: InputMaybe<Scalars['ID']['input']>;
  roomIds?: InputMaybe<Array<Scalars['String']['input']>>;
  rooms?: InputMaybe<Scalars['String']['input']>;
  roomsFrom?: InputMaybe<Scalars['Int']['input']>;
  roomsTo?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<ItemScopeEnum>;
};

/** Autogenerated input type of ItemPay */
export type ItemPayInput = {
  itemId: Scalars['ID']['input'];
};

/** Autogenerated return type of ItemPay. */
export type ItemPayPayload = {
  __typename?: 'ItemPayPayload';
  errors: Array<UserError>;
  item?: Maybe<Item>;
};

/** Autogenerated input type of ItemProlong */
export type ItemProlongInput = {
  itemId: Scalars['ID']['input'];
};

/** Autogenerated return type of ItemProlong. */
export type ItemProlongPayload = {
  __typename?: 'ItemProlongPayload';
  errors: Array<UserError>;
  item?: Maybe<Item>;
};

export enum ItemScopeEnum {
  Featured = 'FEATURED',
  Vipped = 'VIPPED',
  /** Only vipped apartments */
  VippedApartments = 'VIPPED_APARTMENTS',
  /** Vipped items without apartments */
  VippedPure = 'VIPPED_PURE'
}

export enum ItemSort {
  AreaAsc = 'AREA_ASC',
  BumpedAtDesc = 'BUMPED_AT_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  Random = 'RANDOM'
}

export enum ItemStatusEnum {
  AwaitingPayment = 'AWAITING_PAYMENT',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
  Validated = 'VALIDATED'
}

export type ItemUpdateAreaInput = {
  /** Land area */
  land?: InputMaybe<Scalars['Float']['input']>;
  /** Object area */
  value: Scalars['Float']['input'];
};

export type ItemUpdateFloorInput = {
  /** Total floors */
  total: Scalars['Float']['input'];
  /** Object floor */
  value: Scalars['Float']['input'];
};

/** Autogenerated input type of ItemUpdate */
export type ItemUpdateInput = {
  area?: InputMaybe<ItemUpdateAreaInput>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<ItemUpdateFloorInput>;
  hasBillOfSale?: InputMaybe<Scalars['Boolean']['input']>;
  hasMortgage?: InputMaybe<Scalars['Boolean']['input']>;
  hasRepair?: InputMaybe<Scalars['Boolean']['input']>;
  itemId: Scalars['ID']['input'];
  paidDaily?: InputMaybe<Scalars['Boolean']['input']>;
  photoIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  price?: InputMaybe<Scalars['Int']['input']>;
  rooms?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of ItemUpdate. */
export type ItemUpdatePayload = {
  __typename?: 'ItemUpdatePayload';
  errors: Array<UserError>;
  item?: Maybe<Item>;
  /** Time until which editing is locked */
  lockedUntil?: Maybe<Scalars['DateTime']['output']>;
  status: ItemUpdateStatusEnum;
};

export enum ItemUpdateStatusEnum {
  /** No access to an item */
  Forbidden = 'FORBIDDEN',
  /** Item data contains errors */
  Invalid = 'INVALID',
  /** Editing is locked */
  Locked = 'LOCKED',
  NotFound = 'NOT_FOUND',
  /** Nothing to change */
  NotModified = 'NOT_MODIFIED',
  /** Item successfully updated */
  Success = 'SUCCESS'
}

/** User actions on the item */
export enum ItemUserActionsEnum {
  /** A user can edit an item */
  Edit = 'EDIT',
  /** A user can pay for an item using paid items balance without purchasing a paid service */
  Pay = 'PAY',
  /** A user can prolong an item using paid items balance without purchasing a paid service */
  Prolong = 'PROLONG',
  /** A user can remove an item */
  Remove = 'REMOVE'
}

export type Keyword = {
  __typename?: 'Keyword';
  id: Scalars['ID']['output'];
  page: Scalars['String']['output'];
  term: Scalars['String']['output'];
  url: Scalars['URL']['output'];
};

export type KeywordFilter = {
  path?: InputMaybe<Scalars['Path']['input']>;
};

export type Location = {
  __typename?: 'Location';
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  locationGroup: LocationGroup;
  locationGroupId: Scalars['ID']['output'];
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Location>;
  path: Scalars['Path']['output'];
  slug: Scalars['String']['output'];
};

export type LocationFilter = {
  cityId?: InputMaybe<Scalars['ID']['input']>;
  scope?: LocationScopeEnum;
};

export type LocationGroup = {
  __typename?: 'LocationGroup';
  id: Scalars['ID']['output'];
  locations: Array<Location>;
  name?: Maybe<Scalars['String']['output']>;
};


export type LocationGroupLocationsArgs = {
  filter?: InputMaybe<LocationGroupLocationFilter>;
};

export type LocationGroupFilter = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LocationGroupLocationFilter = {
  cityId?: InputMaybe<Scalars['ID']['input']>;
};

export enum LocationScopeEnum {
  All = 'ALL',
  Popular = 'POPULAR'
}

/** Part of the phone is hidden */
export type MaskedPhone = {
  __typename?: 'MaskedPhone';
  value: Scalars['String']['output'];
};

export type MetaTag = {
  __typename?: 'MetaTag';
  content?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeProfilePassword?: Maybe<ProfileChangePasswordPayload>;
  createAnalyticLog?: Maybe<AnalyticLogCreatePayload>;
  createBookmark?: Maybe<BookmarkCreatePayload>;
  createCard?: Maybe<CardCreatePayload>;
  createInvoiceForPackage?: Maybe<InvoiceCreateForPackagePayload>;
  createItem?: Maybe<ItemCreatePayload>;
  createPayment?: Maybe<PaymentCreatePayload>;
  createReport?: Maybe<ReportCreatePayload>;
  deleteBookmark?: Maybe<BookmarkDeletePayload>;
  deleteCard?: Maybe<CardDeletePayload>;
  /** Pay for validated or awaiting payment Item from current user paid item balance */
  payForItem?: Maybe<ItemPayPayload>;
  /** Prolong Item from current user paid item balance */
  prolongItem?: Maybe<ItemProlongPayload>;
  readNotification?: Maybe<NotificationReadPayload>;
  requestPasswordReset?: Maybe<ProfileRequestPasswordResetPayload>;
  resetPassword?: Maybe<ProfileResetPasswordPayload>;
  setPrimaryCard?: Maybe<CardSetPrimaryPayload>;
  signIn?: Maybe<ProfileSignInPayload>;
  signOut?: Maybe<ProfileSignOutPayload>;
  updateCard?: Maybe<CardUpdatePayload>;
  updateItem?: Maybe<ItemUpdatePayload>;
  updateProfile?: Maybe<ProfileUpdatePayload>;
};


export type MutationChangeProfilePasswordArgs = {
  input: ProfileChangePasswordInput;
};


export type MutationCreateAnalyticLogArgs = {
  input: AnalyticLogCreateInput;
};


export type MutationCreateBookmarkArgs = {
  itemId: Scalars['ID']['input'];
};


export type MutationCreateInvoiceForPackageArgs = {
  input: InvoiceCreateForPackageInput;
};


export type MutationCreateItemArgs = {
  input: ItemCreateInput;
};


export type MutationCreatePaymentArgs = {
  input: PaymentCreateInput;
};


export type MutationCreateReportArgs = {
  input: ReportCreateInput;
};


export type MutationDeleteBookmarkArgs = {
  itemId: Scalars['ID']['input'];
};


export type MutationDeleteCardArgs = {
  input: CardDeleteInput;
};


export type MutationPayForItemArgs = {
  input: ItemPayInput;
};


export type MutationProlongItemArgs = {
  input: ItemProlongInput;
};


export type MutationReadNotificationArgs = {
  input: NotificationReadInput;
};


export type MutationRequestPasswordResetArgs = {
  input: ProfileRequestPasswordResetInput;
};


export type MutationResetPasswordArgs = {
  input: ProfileResetPasswordInput;
};


export type MutationSetPrimaryCardArgs = {
  input: CardSetPrimaryInput;
};


export type MutationSignInArgs = {
  input: ProfileSignInInput;
};


export type MutationUpdateCardArgs = {
  input: CardUpdateInput;
};


export type MutationUpdateItemArgs = {
  input: ItemUpdateInput;
};


export type MutationUpdateProfileArgs = {
  input: ProfileUpdateInput;
};

export type NearestLocation = City | Location;

/** Autogenerated input type of NotificationRead */
export type NotificationReadInput = {
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of NotificationRead. */
export type NotificationReadPayload = {
  __typename?: 'NotificationReadPayload';
  errors: Array<UserError>;
};

/** Prices for Object which contain Items */
export type ObjectPrice = Currency & {
  __typename?: 'ObjectPrice';
  currency: Scalars['String']['output'];
  perUnit?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<TotalPrice>;
};

export type Package = {
  __typename?: 'Package';
  discount?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  paidAdsCount?: Maybe<Scalars['Int']['output']>;
  periods: Array<PackagePeriod>;
  price?: Maybe<Scalars['Float']['output']>;
  promotions: Array<Scalars['String']['output']>;
  walletAmount?: Maybe<Scalars['Float']['output']>;
};

export type PackagePeriod = {
  __typename?: 'PackagePeriod';
  price: Scalars['Float']['output'];
  value?: Maybe<Scalars['Int']['output']>;
};

export type Page = {
  __typename?: 'Page';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PageSlugEnum {
  About = 'ABOUT',
  Ad = 'AD',
  Packages = 'PACKAGES',
  Privacy = 'PRIVACY',
  Proposal = 'PROPOSAL',
  Rules = 'RULES',
  Terms = 'TERMS'
}

export type PaidService = {
  __typename?: 'PaidService';
  /** Time until which the paid service is active */
  activeUntil?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  options: Array<PaidServiceOption>;
  /** Payment methods for current user */
  paymentMethods: Array<PaymentMethod>;
  /** Type of target (Item, Company, etc.) */
  targetType: PaidServiceTargetTypeEnum;
  title?: Maybe<Scalars['String']['output']>;
};

/** Paid service option such as duration, quantity, etc. */
export type PaidServiceOption = {
  __typename?: 'PaidServiceOption';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Initial price of paid service option. Doesn't take into account discounts, etc. */
  price: Scalars['Float']['output'];
  /** Final price of paid service option for current user and selected payment method. Takes into account discounts, etc. */
  priceWithDiscount?: Maybe<Scalars['Float']['output']>;
};


/** Paid service option such as duration, quantity, etc. */
export type PaidServiceOptionPriceWithDiscountArgs = {
  paymentMethodId: Scalars['ID']['input'];
};

export enum PaidServiceTargetTypeEnum {
  Company = 'COMPANY',
  Invoice = 'INVOICE',
  Item = 'ITEM'
}

export enum PaidServicesTargetTypeEnum {
  Item = 'ITEM'
}

export type Payment = {
  __typename?: 'Payment';
  /** Amount of payment (positive value) */
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  discount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  item?: Maybe<Item>;
  paidServiceOptionName: Scalars['String']['output'];
  paymentMethodName: Scalars['String']['output'];
  /** A change in the amount of money (positive or negative value) */
  total: Scalars['Float']['output'];
};

/** The connection type for Payment. */
export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Payment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of PaymentCreate */
export type PaymentCreateInput = {
  /** Amount for non-fixed price paid services */
  amount?: InputMaybe<Scalars['Float']['input']>;
  cardToken?: InputMaybe<Scalars['String']['input']>;
  paidServiceId: Scalars['ID']['input'];
  paidServiceOptionId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId: Scalars['ID']['input'];
  platform?: InputMaybe<PaymentCreatePlatformEnum>;
  /** Target ids for given paid service */
  targetId: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of PaymentCreate. */
export type PaymentCreatePayload = {
  __typename?: 'PaymentCreatePayload';
  errors: Array<UserError>;
  /** Payment index */
  paymentId?: Maybe<Scalars['ID']['output']>;
  /** If provided, follow the URL to continue payment */
  redirectUrl?: Maybe<Scalars['URL']['output']>;
  status: PaymentCreateStatusEnum;
};

/** Type of platform the request come from */
export enum PaymentCreatePlatformEnum {
  Api = 'API',
  App = 'APP',
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE'
}

export enum PaymentCreateStatusEnum {
  Failure = 'FAILURE',
  /** Follow to the URL provided */
  Redirect = 'REDIRECT',
  Success = 'SUCCESS'
}

/** An edge in a connection. */
export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Payment>;
};

/** Method of payment by the user for a paid service */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  /** User balance that can be spent on the paid service */
  balance?: Maybe<Scalars['Float']['output']>;
  cardToken?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['AssetURL']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PersonalAccountTransaction = {
  __typename?: 'PersonalAccountTransaction';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  payment?: Maybe<Payment>;
  total: Scalars['Float']['output'];
};

/** The connection type for PersonalAccountTransaction. */
export type PersonalAccountTransactionConnection = {
  __typename?: 'PersonalAccountTransactionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PersonalAccountTransactionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PersonalAccountTransaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total # of objects returned from this Plural Query */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PersonalAccountTransactionEdge = {
  __typename?: 'PersonalAccountTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PersonalAccountTransaction>;
};

/** Formatted full phone number */
export type Phone = {
  __typename?: 'Phone';
  value: Scalars['String']['output'];
};

export type Photo = {
  __typename?: 'Photo';
  f460x345?: Maybe<Scalars['AssetURL']['output']>;
  f660x496?: Maybe<Scalars['AssetURL']['output']>;
  full?: Maybe<Scalars['AssetURL']['output']>;
  large?: Maybe<Scalars['AssetURL']['output']>;
  thumbnail?: Maybe<Scalars['AssetURL']['output']>;
};

export type Price = Currency & {
  __typename?: 'Price';
  currency: Scalars['String']['output'];
  value?: Maybe<Scalars['Int']['output']>;
};

/** Abstraction of current authenticated user */
export type Profile = CompanyProfile;

/** Autogenerated input type of ProfileChangePassword */
export type ProfileChangePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

/** Autogenerated return type of ProfileChangePassword. */
export type ProfileChangePasswordPayload = {
  __typename?: 'ProfileChangePasswordPayload';
  errors: Array<UserError>;
  profile?: Maybe<Profile>;
  status: ProfileChangePasswordStatusEnum;
};

export enum ProfileChangePasswordStatusEnum {
  /** Data contains errors */
  Invalid = 'INVALID',
  /** Successfully created */
  Success = 'SUCCESS'
}

export enum ProfileItemTabStatesEnum {
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
  ValidatedAndAwaitingPayment = 'VALIDATED_AND_AWAITING_PAYMENT'
}

/** Autogenerated input type of ProfileRequestPasswordReset */
export type ProfileRequestPasswordResetInput = {
  email: Scalars['String']['input'];
};

/** Autogenerated return type of ProfileRequestPasswordReset. */
export type ProfileRequestPasswordResetPayload = {
  __typename?: 'ProfileRequestPasswordResetPayload';
  errors: Array<UserError>;
  status: RequestPasswordResetStatusEnum;
};

/** Autogenerated input type of ProfileResetPassword */
export type ProfileResetPasswordInput = {
  newPassword: Scalars['String']['input'];
  /** Reset password token */
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ProfileResetPassword. */
export type ProfileResetPasswordPayload = {
  __typename?: 'ProfileResetPasswordPayload';
  errors: Array<UserError>;
  status: ResetPasswordStatusEnum;
};

export type ProfileSignInInput = {
  /** Access token received from Digit Auth */
  accessToken?: InputMaybe<Scalars['String']['input']>;
  emailAndPassword?: InputMaybe<EmailAndPasswordInput>;
};

/** Autogenerated return type of ProfileSignIn. */
export type ProfileSignInPayload = {
  __typename?: 'ProfileSignInPayload';
  errors: Array<UserError>;
  profile?: Maybe<Profile>;
};

/** Autogenerated return type of ProfileSignOut. */
export type ProfileSignOutPayload = {
  __typename?: 'ProfileSignOutPayload';
  errors: Array<UserError>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of ProfileUpdate */
export type ProfileUpdateInput = {
  alternativePhone?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ProfileUpdate. */
export type ProfileUpdatePayload = {
  __typename?: 'ProfileUpdatePayload';
  errors: Array<UserError>;
  profile?: Maybe<Profile>;
  status: ProfileUpdateStatusEnum;
};

export enum ProfileUpdateStatusEnum {
  /** Data contains errors */
  Invalid = 'INVALID',
  /** Successfully created */
  Success = 'SUCCESS'
}

export type Query = {
  __typename?: 'Query';
  agencies: Array<Agency>;
  agency?: Maybe<Agency>;
  bookmarks: Array<Bookmark>;
  card?: Maybe<Card>;
  cards?: Maybe<Array<Card>>;
  categories: Array<Category>;
  cities: Array<City>;
  city?: Maybe<City>;
  /** Temporary primitive-types from Rails. Exposed to the very next action and then cleared out. */
  flashMessages: Array<FlashMessage>;
  /** Invoice for current user */
  invoice?: Maybe<Invoice>;
  /** Current user invoices */
  invoices: InvoiceConnection;
  item?: Maybe<Item>;
  /** Current user's item balance history */
  itemBalanceHistory: ItemBalanceTransactionConnection;
  /** Original and pending data for Item edit */
  itemEdit?: Maybe<ItemEdit>;
  items: Array<EsItem>;
  /** Items for lists with cursor pagination */
  itemsConnection: EsItemConnection;
  itemsPublishedTodayCount: Scalars['Int']['output'];
  keywords: Array<Keyword>;
  landmarks: Array<Location>;
  locationGroups: Array<LocationGroup>;
  locations: Array<Location>;
  /** Current user packages */
  packages: Array<Package>;
  page?: Maybe<Page>;
  /** Paid service options such as duration, quantity, etc. for a specific paid service */
  paidServiceOptions: Array<PaidServiceOption>;
  paidServices: Array<PaidService>;
  /** Current user's payment history */
  paymentHistory: PaymentConnection;
  /** Methods of payment by the user for a paid service */
  paymentMethods: Array<PaymentMethod>;
  /** Current user's wallet balance history */
  personalAccountBalanceHistory: PersonalAccountTransactionConnection;
  profile?: Maybe<Profile>;
  regions: Array<Location>;
  /** Report reasons sorted by their position */
  reportReasons?: Maybe<Array<ReportReason>>;
  residence?: Maybe<Residence>;
  residences: Array<Residence>;
  seo?: Maybe<Seo>;
  townships: Array<Location>;
};


export type QueryAgenciesArgs = {
  limit?: Scalars['AgencyLimit']['input'];
  sort?: InputMaybe<AgencySort>;
};


export type QueryAgencyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBookmarksArgs = {
  limit?: Scalars['Limit']['input'];
};


export type QueryCardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCategoriesArgs = {
  filter?: CategoryFilter;
  limit?: Scalars['Limit']['input'];
};


export type QueryCitiesArgs = {
  filter?: CityFilter;
  limit?: Scalars['CityLimit']['input'];
};


export type QueryCityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<InvoiceScopeEnum>;
};


export type QueryItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryItemBalanceHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryItemEditArgs = {
  itemId: Scalars['ID']['input'];
};


export type QueryItemsArgs = {
  filter?: InputMaybe<ItemFilter>;
  limit?: Scalars['Limit']['input'];
  offset?: Scalars['Int']['input'];
  sort?: ItemSort;
};


export type QueryItemsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: ItemConnectionSort;
};


export type QueryKeywordsArgs = {
  filter?: InputMaybe<KeywordFilter>;
  limit?: Scalars['Limit']['input'];
};


export type QueryLandmarksArgs = {
  cityId: Scalars['ID']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};


export type QueryLocationGroupsArgs = {
  filter?: InputMaybe<LocationGroupFilter>;
  limit?: Scalars['Limit']['input'];
};


export type QueryLocationsArgs = {
  filter?: LocationFilter;
  limit?: Scalars['Int']['input'];
};


export type QueryPageArgs = {
  slug: PageSlugEnum;
};


export type QueryPaidServiceOptionsArgs = {
  paidServiceId: Scalars['ID']['input'];
  targetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryPaidServicesArgs = {
  targetIds: Array<Scalars['ID']['input']>;
  targetType: PaidServicesTargetTypeEnum;
};


export type QueryPaymentHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaymentMethodsArgs = {
  paidServiceId: Scalars['ID']['input'];
  targetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  targetType?: InputMaybe<PaidServiceTargetTypeEnum>;
};


export type QueryPersonalAccountBalanceHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRegionsArgs = {
  cityId: Scalars['ID']['input'];
};


export type QueryResidenceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryResidencesArgs = {
  limit?: Scalars['EstablishmentLimit']['input'];
  sort?: InputMaybe<ResidenceSort>;
};


export type QuerySeoArgs = {
  path: Scalars['Path']['input'];
};


export type QueryTownshipsArgs = {
  regionId: Scalars['ID']['input'];
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of ReportCreate */
export type ReportCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  itemId: Scalars['ID']['input'];
  reportReasonId: Scalars['ID']['input'];
};

/** Autogenerated return type of ReportCreate. */
export type ReportCreatePayload = {
  __typename?: 'ReportCreatePayload';
  errors: Array<UserError>;
  report?: Maybe<Report>;
};

export type ReportReason = {
  __typename?: 'ReportReason';
  id: Scalars['ID']['output'];
  /** Short description */
  reason: Scalars['String']['output'];
};

export enum RequestPasswordResetStatusEnum {
  /** General error */
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export enum ResetPasswordStatusEnum {
  /** General error */
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type Residence = {
  __typename?: 'Residence';
  address?: Maybe<Scalars['String']['output']>;
  /** List of unique rooms for available apartments */
  apartmentRooms?: Maybe<Array<Scalars['Int']['output']>>;
  apartments?: Maybe<Array<Apartment>>;
  apartmentsCount?: Maybe<Scalars['Int']['output']>;
  apartmentsPerFloor?: Maybe<Scalars['String']['output']>;
  blocks?: Maybe<Scalars['String']['output']>;
  briefInfo?: Maybe<Scalars['String']['output']>;
  brochureUrl?: Maybe<Scalars['AssetURL']['output']>;
  buildingClass?: Maybe<BuildingProperty>;
  buildingsCount?: Maybe<Scalars['Int']['output']>;
  ceilingHeight?: Maybe<Scalars['Int']['output']>;
  city: City;
  companyName: Scalars['String']['output'];
  constructionProgresses: Array<ResidenceConstructionProgress>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  coverUrl?: Maybe<Scalars['AssetURL']['output']>;
  deadline?: Maybe<Scalars['String']['output']>;
  decoration?: Maybe<BuildingProperty>;
  description?: Maybe<Scalars['String']['output']>;
  facilities: Array<BuildingProperty>;
  floorsPerBuilding?: Maybe<Scalars['String']['output']>;
  hasBillOfSale: Scalars['Boolean']['output'];
  hasCollageGallery: Scalars['Boolean']['output'];
  hits?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  infrastructures: Array<ResidenceInfrastructure>;
  /** List of unique rooms for available items */
  itemRooms?: Maybe<Array<Scalars['Int']['output']>>;
  itemsCount: Scalars['Int']['output'];
  lat?: Maybe<Scalars['Float']['output']>;
  layout?: Maybe<BuildingProperty>;
  liftsPerBlock?: Maybe<Scalars['String']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  locations?: Maybe<Array<Location>>;
  logoUrl?: Maybe<Scalars['AssetURL']['output']>;
  metroName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  path: Scalars['Path']['output'];
  phonesMasked: Array<Scalars['String']['output']>;
  photos?: Maybe<Array<Photo>>;
  price: ObjectPrice;
  schedule?: Maybe<Scalars['String']['output']>;
  shortAddress?: Maybe<Scalars['String']['output']>;
  showBrandHeader?: Maybe<Scalars['Boolean']['output']>;
  specialOffers: Array<ResidenceSpecialOffer>;
  technology?: Maybe<BuildingProperty>;
  updatedAt: Scalars['DateTime']['output'];
  website?: Maybe<Scalars['String']['output']>;
  windowType?: Maybe<BuildingProperty>;
  youtubeVideoId?: Maybe<Scalars['String']['output']>;
};


export type ResidencePhotosArgs = {
  limit?: InputMaybe<Scalars['PhotoLimit']['input']>;
};

export type ResidenceConstructionProgress = {
  __typename?: 'ResidenceConstructionProgress';
  date: Scalars['DateTime']['output'];
  isPromo: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  photos?: Maybe<Array<Photo>>;
  youtubeVideoId?: Maybe<Scalars['String']['output']>;
};


export type ResidenceConstructionProgressPhotosArgs = {
  limit?: InputMaybe<Scalars['PhotoLimit']['input']>;
};

export type ResidenceInfrastructure = {
  __typename?: 'ResidenceInfrastructure';
  distance: Scalars['Int']['output'];
  infrastructure?: Maybe<BuildingProperty>;
};

export enum ResidenceSort {
  Random = 'RANDOM'
}

export type ResidenceSpecialOffer = {
  __typename?: 'ResidenceSpecialOffer';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  offerType: ResidenceSpecialOfferTypeEnum;
};

export enum ResidenceSpecialOfferTypeEnum {
  InternalLoan = 'INTERNAL_LOAN',
  Mortgage = 'MORTGAGE',
  Promotion = 'PROMOTION'
}

export type Seo = {
  __typename?: 'SEO';
  description?: Maybe<Scalars['String']['output']>;
  h1?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type TotalPrice = {
  __typename?: 'TotalPrice';
  maximum?: Maybe<Scalars['Int']['output']>;
  minimum?: Maybe<Scalars['Int']['output']>;
};

/** A readable user/business-level error */
export type UserError = {
  __typename?: 'UserError';
  /** Corresponding error code */
  code?: Maybe<Scalars['String']['output']>;
  /** A description of the error */
  message: Scalars['String']['output'];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']['output']>>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  Business: ( Omit<Agency, 'items'> & { items: Array<_RefType['Item']> } ) | ( Residence );
  DisplayedLocation: ( City ) | ( Location );
  DisplayedPhone: ( MaskedPhone ) | ( Phone );
  ItemBalanceTransactionSource: ( Omit<Item, 'area' | 'business' | 'displayedLocation' | 'landArea' | 'nearestLocations' | 'phones'> & { area: _RefType['Area'], business?: Maybe<_RefType['Business']>, displayedLocation?: Maybe<_RefType['DisplayedLocation']>, landArea?: Maybe<_RefType['Area']>, nearestLocations?: Maybe<Array<_RefType['NearestLocation']>>, phones: Array<_RefType['DisplayedPhone']> } ) | ( Omit<Payment, 'item'> & { item?: Maybe<_RefType['Item']> } );
  NearestLocation: ( City ) | ( Location );
  Profile: ( Omit<CompanyProfile, 'business' | 'items'> & { business?: Maybe<_RefType['Business']>, items: _RefType['ItemConnection'] } );
};

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  BaseCompany: ( Company ) | ( Omit<CompanyProfile, 'business' | 'items'> & { business?: Maybe<_RefType['Business']>, items: _RefType['ItemConnection'] } );
  Currency: ( Apartment ) | ( EsPrice ) | ( ObjectPrice ) | ( Price );
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Agency: ResolverTypeWrapper<Omit<Agency, 'items'> & { items: Array<ResolversTypes['Item']> }>;
  AgencyItemSort: AgencyItemSort;
  AgencyLimit: ResolverTypeWrapper<Scalars['AgencyLimit']['output']>;
  AgencySort: AgencySort;
  AnalyticLogCreateInput: AnalyticLogCreateInput;
  AnalyticLogCreatePayload: ResolverTypeWrapper<AnalyticLogCreatePayload>;
  AnalyticLogEventTypeEnum: AnalyticLogEventTypeEnum;
  AnalyticLogSourceEnum: AnalyticLogSourceEnum;
  AnalyticLogTargetTypeEnum: AnalyticLogTargetTypeEnum;
  Apartment: ResolverTypeWrapper<Apartment>;
  Area: ResolverTypeWrapper<Area>;
  AssetURL: ResolverTypeWrapper<Scalars['AssetURL']['output']>;
  BaseCompany: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['BaseCompany']>;
  Bookmark: ResolverTypeWrapper<Omit<Bookmark, 'item'> & { item: ResolversTypes['Item'] }>;
  BookmarkCreatePayload: ResolverTypeWrapper<Omit<BookmarkCreatePayload, 'bookmark'> & { bookmark?: Maybe<ResolversTypes['Bookmark']> }>;
  BookmarkDeletePayload: ResolverTypeWrapper<Omit<BookmarkDeletePayload, 'bookmark'> & { bookmark?: Maybe<ResolversTypes['Bookmark']> }>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Breadcrumb: ResolverTypeWrapper<Breadcrumb>;
  BuildingProperty: ResolverTypeWrapper<BuildingProperty>;
  Business: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Business']>;
  Card: ResolverTypeWrapper<Card>;
  CardCreatePayload: ResolverTypeWrapper<CardCreatePayload>;
  CardCreateStatusEnum: CardCreateStatusEnum;
  CardDeleteInput: CardDeleteInput;
  CardDeletePayload: ResolverTypeWrapper<CardDeletePayload>;
  CardSetPrimaryInput: CardSetPrimaryInput;
  CardSetPrimaryPayload: ResolverTypeWrapper<CardSetPrimaryPayload>;
  CardUpdateInput: CardUpdateInput;
  CardUpdatePayload: ResolverTypeWrapper<CardUpdatePayload>;
  CardUpdateStatusEnum: CardUpdateStatusEnum;
  Category: ResolverTypeWrapper<Category>;
  CategoryFilter: CategoryFilter;
  CategoryScopeEnum: CategoryScopeEnum;
  City: ResolverTypeWrapper<City>;
  CityFilter: CityFilter;
  CityLimit: ResolverTypeWrapper<Scalars['CityLimit']['output']>;
  CityScopeEnum: CityScopeEnum;
  Company: ResolverTypeWrapper<Company>;
  CompanyBalance: ResolverTypeWrapper<CompanyBalance>;
  CompanyItemBalance: ResolverTypeWrapper<CompanyItemBalance>;
  CompanyNotification: ResolverTypeWrapper<CompanyNotification>;
  CompanyPersonalAccountBalance: ResolverTypeWrapper<CompanyPersonalAccountBalance>;
  CompanyProfile: ResolverTypeWrapper<Omit<CompanyProfile, 'business' | 'items'> & { business?: Maybe<ResolversTypes['Business']>, items: ResolversTypes['ItemConnection'] }>;
  CompanyTargetTypeEnum: CompanyTargetTypeEnum;
  Currency: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Currency']>;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DisplayedLocation: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DisplayedLocation']>;
  DisplayedPhone: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DisplayedPhone']>;
  ESArea: ResolverTypeWrapper<EsArea>;
  ESItem: ResolverTypeWrapper<EsItem>;
  ESItemConnection: ResolverTypeWrapper<EsItemConnection>;
  ESItemEdge: ResolverTypeWrapper<EsItemEdge>;
  ESPhoto: ResolverTypeWrapper<EsPhoto>;
  ESPrice: ResolverTypeWrapper<EsPrice>;
  Edit: ResolverTypeWrapper<Omit<Edit, 'area' | 'landArea'> & { area?: Maybe<ResolversTypes['Area']>, landArea?: Maybe<ResolversTypes['Area']> }>;
  EmailAndPasswordInput: EmailAndPasswordInput;
  EstablishmentLimit: ResolverTypeWrapper<Scalars['EstablishmentLimit']['output']>;
  FlashMessage: ResolverTypeWrapper<FlashMessage>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Invoice: ResolverTypeWrapper<Omit<Invoice, 'payment'> & { payment?: Maybe<ResolversTypes['Payment']> }>;
  InvoiceConnection: ResolverTypeWrapper<Omit<InvoiceConnection, 'edges' | 'nodes'> & { edges?: Maybe<Array<Maybe<ResolversTypes['InvoiceEdge']>>>, nodes?: Maybe<Array<Maybe<ResolversTypes['Invoice']>>> }>;
  InvoiceCreateForPackageActivationTypeEnum: InvoiceCreateForPackageActivationTypeEnum;
  InvoiceCreateForPackageInput: InvoiceCreateForPackageInput;
  InvoiceCreateForPackagePayload: ResolverTypeWrapper<Omit<InvoiceCreateForPackagePayload, 'invoice'> & { invoice?: Maybe<ResolversTypes['Invoice']> }>;
  InvoiceEdge: ResolverTypeWrapper<Omit<InvoiceEdge, 'node'> & { node?: Maybe<ResolversTypes['Invoice']> }>;
  InvoiceScopeEnum: InvoiceScopeEnum;
  InvoiceStatusEnum: InvoiceStatusEnum;
  Item: ResolverTypeWrapper<Omit<Item, 'area' | 'business' | 'displayedLocation' | 'landArea' | 'nearestLocations' | 'phones'> & { area: ResolversTypes['Area'], business?: Maybe<ResolversTypes['Business']>, displayedLocation?: Maybe<ResolversTypes['DisplayedLocation']>, landArea?: Maybe<ResolversTypes['Area']>, nearestLocations?: Maybe<Array<ResolversTypes['NearestLocation']>>, phones: Array<ResolversTypes['DisplayedPhone']> }>;
  ItemBalanceTransaction: ResolverTypeWrapper<Omit<ItemBalanceTransaction, 'source'> & { source?: Maybe<ResolversTypes['ItemBalanceTransactionSource']> }>;
  ItemBalanceTransactionConnection: ResolverTypeWrapper<Omit<ItemBalanceTransactionConnection, 'edges' | 'nodes'> & { edges?: Maybe<Array<Maybe<ResolversTypes['ItemBalanceTransactionEdge']>>>, nodes?: Maybe<Array<Maybe<ResolversTypes['ItemBalanceTransaction']>>> }>;
  ItemBalanceTransactionEdge: ResolverTypeWrapper<Omit<ItemBalanceTransactionEdge, 'node'> & { node?: Maybe<ResolversTypes['ItemBalanceTransaction']> }>;
  ItemBalanceTransactionSource: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['ItemBalanceTransactionSource']>;
  ItemBuildingTypeEnum: ItemBuildingTypeEnum;
  ItemConnection: ResolverTypeWrapper<Omit<ItemConnection, 'edges' | 'nodes'> & { edges?: Maybe<Array<Maybe<ResolversTypes['ItemEdge']>>>, nodes?: Maybe<Array<Maybe<ResolversTypes['Item']>>> }>;
  ItemConnectionSort: ItemConnectionSort;
  ItemCreateAreaInput: ItemCreateAreaInput;
  ItemCreateBuildingTypeEnum: ItemCreateBuildingTypeEnum;
  ItemCreateContactInput: ItemCreateContactInput;
  ItemCreateContactTypeEnum: ItemCreateContactTypeEnum;
  ItemCreateFloorInput: ItemCreateFloorInput;
  ItemCreateInput: ItemCreateInput;
  ItemCreateLocationInput: ItemCreateLocationInput;
  ItemCreatePayload: ResolverTypeWrapper<Omit<ItemCreatePayload, 'item'> & { item?: Maybe<ResolversTypes['Item']> }>;
  ItemCreateStatusEnum: ItemCreateStatusEnum;
  ItemEdge: ResolverTypeWrapper<Omit<ItemEdge, 'node'> & { node?: Maybe<ResolversTypes['Item']> }>;
  ItemEdit: ResolverTypeWrapper<Omit<ItemEdit, 'edit' | 'item'> & { edit: ResolversTypes['Edit'], item: ResolversTypes['Item'] }>;
  ItemFilter: ItemFilter;
  ItemPayInput: ItemPayInput;
  ItemPayPayload: ResolverTypeWrapper<Omit<ItemPayPayload, 'item'> & { item?: Maybe<ResolversTypes['Item']> }>;
  ItemProlongInput: ItemProlongInput;
  ItemProlongPayload: ResolverTypeWrapper<Omit<ItemProlongPayload, 'item'> & { item?: Maybe<ResolversTypes['Item']> }>;
  ItemScopeEnum: ItemScopeEnum;
  ItemSort: ItemSort;
  ItemStatusEnum: ItemStatusEnum;
  ItemUpdateAreaInput: ItemUpdateAreaInput;
  ItemUpdateFloorInput: ItemUpdateFloorInput;
  ItemUpdateInput: ItemUpdateInput;
  ItemUpdatePayload: ResolverTypeWrapper<Omit<ItemUpdatePayload, 'item'> & { item?: Maybe<ResolversTypes['Item']> }>;
  ItemUpdateStatusEnum: ItemUpdateStatusEnum;
  ItemUserActionsEnum: ItemUserActionsEnum;
  Keyword: ResolverTypeWrapper<Keyword>;
  KeywordFilter: KeywordFilter;
  Limit: ResolverTypeWrapper<Scalars['Limit']['output']>;
  Location: ResolverTypeWrapper<Location>;
  LocationFilter: LocationFilter;
  LocationGroup: ResolverTypeWrapper<LocationGroup>;
  LocationGroupFilter: LocationGroupFilter;
  LocationGroupLocationFilter: LocationGroupLocationFilter;
  LocationScopeEnum: LocationScopeEnum;
  MaskedPhone: ResolverTypeWrapper<MaskedPhone>;
  MetaTag: ResolverTypeWrapper<MetaTag>;
  Mutation: ResolverTypeWrapper<{}>;
  NearestLocation: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['NearestLocation']>;
  NotificationReadInput: NotificationReadInput;
  NotificationReadPayload: ResolverTypeWrapper<NotificationReadPayload>;
  ObjectPrice: ResolverTypeWrapper<ObjectPrice>;
  Package: ResolverTypeWrapper<Package>;
  PackagePeriod: ResolverTypeWrapper<PackagePeriod>;
  Page: ResolverTypeWrapper<Page>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PageSlugEnum: PageSlugEnum;
  PaidService: ResolverTypeWrapper<PaidService>;
  PaidServiceOption: ResolverTypeWrapper<PaidServiceOption>;
  PaidServiceTargetTypeEnum: PaidServiceTargetTypeEnum;
  PaidServicesTargetTypeEnum: PaidServicesTargetTypeEnum;
  Path: ResolverTypeWrapper<Scalars['Path']['output']>;
  Payment: ResolverTypeWrapper<Omit<Payment, 'item'> & { item?: Maybe<ResolversTypes['Item']> }>;
  PaymentConnection: ResolverTypeWrapper<Omit<PaymentConnection, 'edges' | 'nodes'> & { edges?: Maybe<Array<Maybe<ResolversTypes['PaymentEdge']>>>, nodes?: Maybe<Array<Maybe<ResolversTypes['Payment']>>> }>;
  PaymentCreateInput: PaymentCreateInput;
  PaymentCreatePayload: ResolverTypeWrapper<PaymentCreatePayload>;
  PaymentCreatePlatformEnum: PaymentCreatePlatformEnum;
  PaymentCreateStatusEnum: PaymentCreateStatusEnum;
  PaymentEdge: ResolverTypeWrapper<Omit<PaymentEdge, 'node'> & { node?: Maybe<ResolversTypes['Payment']> }>;
  PaymentMethod: ResolverTypeWrapper<PaymentMethod>;
  PersonalAccountTransaction: ResolverTypeWrapper<Omit<PersonalAccountTransaction, 'payment'> & { payment?: Maybe<ResolversTypes['Payment']> }>;
  PersonalAccountTransactionConnection: ResolverTypeWrapper<Omit<PersonalAccountTransactionConnection, 'edges' | 'nodes'> & { edges?: Maybe<Array<Maybe<ResolversTypes['PersonalAccountTransactionEdge']>>>, nodes?: Maybe<Array<Maybe<ResolversTypes['PersonalAccountTransaction']>>> }>;
  PersonalAccountTransactionEdge: ResolverTypeWrapper<Omit<PersonalAccountTransactionEdge, 'node'> & { node?: Maybe<ResolversTypes['PersonalAccountTransaction']> }>;
  Phone: ResolverTypeWrapper<Phone>;
  Photo: ResolverTypeWrapper<Photo>;
  PhotoLimit: ResolverTypeWrapper<Scalars['PhotoLimit']['output']>;
  Price: ResolverTypeWrapper<Price>;
  Profile: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Profile']>;
  ProfileChangePasswordInput: ProfileChangePasswordInput;
  ProfileChangePasswordPayload: ResolverTypeWrapper<Omit<ProfileChangePasswordPayload, 'profile'> & { profile?: Maybe<ResolversTypes['Profile']> }>;
  ProfileChangePasswordStatusEnum: ProfileChangePasswordStatusEnum;
  ProfileItemTabStatesEnum: ProfileItemTabStatesEnum;
  ProfileRequestPasswordResetInput: ProfileRequestPasswordResetInput;
  ProfileRequestPasswordResetPayload: ResolverTypeWrapper<ProfileRequestPasswordResetPayload>;
  ProfileResetPasswordInput: ProfileResetPasswordInput;
  ProfileResetPasswordPayload: ResolverTypeWrapper<ProfileResetPasswordPayload>;
  ProfileSignInInput: ProfileSignInInput;
  ProfileSignInPayload: ResolverTypeWrapper<Omit<ProfileSignInPayload, 'profile'> & { profile?: Maybe<ResolversTypes['Profile']> }>;
  ProfileSignOutPayload: ResolverTypeWrapper<Omit<ProfileSignOutPayload, 'profile'> & { profile?: Maybe<ResolversTypes['Profile']> }>;
  ProfileUpdateInput: ProfileUpdateInput;
  ProfileUpdatePayload: ResolverTypeWrapper<Omit<ProfileUpdatePayload, 'profile'> & { profile?: Maybe<ResolversTypes['Profile']> }>;
  ProfileUpdateStatusEnum: ProfileUpdateStatusEnum;
  Query: ResolverTypeWrapper<{}>;
  Report: ResolverTypeWrapper<Report>;
  ReportCreateInput: ReportCreateInput;
  ReportCreatePayload: ResolverTypeWrapper<ReportCreatePayload>;
  ReportReason: ResolverTypeWrapper<ReportReason>;
  RequestPasswordResetStatusEnum: RequestPasswordResetStatusEnum;
  ResetPasswordStatusEnum: ResetPasswordStatusEnum;
  Residence: ResolverTypeWrapper<Residence>;
  ResidenceConstructionProgress: ResolverTypeWrapper<ResidenceConstructionProgress>;
  ResidenceInfrastructure: ResolverTypeWrapper<ResidenceInfrastructure>;
  ResidenceSort: ResidenceSort;
  ResidenceSpecialOffer: ResolverTypeWrapper<ResidenceSpecialOffer>;
  ResidenceSpecialOfferTypeEnum: ResidenceSpecialOfferTypeEnum;
  SEO: ResolverTypeWrapper<Seo>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  TotalPrice: ResolverTypeWrapper<TotalPrice>;
  URL: ResolverTypeWrapper<Scalars['URL']['output']>;
  UserError: ResolverTypeWrapper<UserError>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Agency: Omit<Agency, 'items'> & { items: Array<ResolversParentTypes['Item']> };
  AgencyLimit: Scalars['AgencyLimit']['output'];
  AnalyticLogCreateInput: AnalyticLogCreateInput;
  AnalyticLogCreatePayload: AnalyticLogCreatePayload;
  Apartment: Apartment;
  Area: Area;
  AssetURL: Scalars['AssetURL']['output'];
  BaseCompany: ResolversInterfaceTypes<ResolversParentTypes>['BaseCompany'];
  Bookmark: Omit<Bookmark, 'item'> & { item: ResolversParentTypes['Item'] };
  BookmarkCreatePayload: Omit<BookmarkCreatePayload, 'bookmark'> & { bookmark?: Maybe<ResolversParentTypes['Bookmark']> };
  BookmarkDeletePayload: Omit<BookmarkDeletePayload, 'bookmark'> & { bookmark?: Maybe<ResolversParentTypes['Bookmark']> };
  Boolean: Scalars['Boolean']['output'];
  Breadcrumb: Breadcrumb;
  BuildingProperty: BuildingProperty;
  Business: ResolversUnionTypes<ResolversParentTypes>['Business'];
  Card: Card;
  CardCreatePayload: CardCreatePayload;
  CardDeleteInput: CardDeleteInput;
  CardDeletePayload: CardDeletePayload;
  CardSetPrimaryInput: CardSetPrimaryInput;
  CardSetPrimaryPayload: CardSetPrimaryPayload;
  CardUpdateInput: CardUpdateInput;
  CardUpdatePayload: CardUpdatePayload;
  Category: Category;
  CategoryFilter: CategoryFilter;
  City: City;
  CityFilter: CityFilter;
  CityLimit: Scalars['CityLimit']['output'];
  Company: Company;
  CompanyBalance: CompanyBalance;
  CompanyItemBalance: CompanyItemBalance;
  CompanyNotification: CompanyNotification;
  CompanyPersonalAccountBalance: CompanyPersonalAccountBalance;
  CompanyProfile: Omit<CompanyProfile, 'business' | 'items'> & { business?: Maybe<ResolversParentTypes['Business']>, items: ResolversParentTypes['ItemConnection'] };
  Currency: ResolversInterfaceTypes<ResolversParentTypes>['Currency'];
  Date: Scalars['Date']['output'];
  DateTime: Scalars['DateTime']['output'];
  DisplayedLocation: ResolversUnionTypes<ResolversParentTypes>['DisplayedLocation'];
  DisplayedPhone: ResolversUnionTypes<ResolversParentTypes>['DisplayedPhone'];
  ESArea: EsArea;
  ESItem: EsItem;
  ESItemConnection: EsItemConnection;
  ESItemEdge: EsItemEdge;
  ESPhoto: EsPhoto;
  ESPrice: EsPrice;
  Edit: Omit<Edit, 'area' | 'landArea'> & { area?: Maybe<ResolversParentTypes['Area']>, landArea?: Maybe<ResolversParentTypes['Area']> };
  EmailAndPasswordInput: EmailAndPasswordInput;
  EstablishmentLimit: Scalars['EstablishmentLimit']['output'];
  FlashMessage: FlashMessage;
  Float: Scalars['Float']['output'];
  ID: Scalars['ID']['output'];
  Int: Scalars['Int']['output'];
  Invoice: Omit<Invoice, 'payment'> & { payment?: Maybe<ResolversParentTypes['Payment']> };
  InvoiceConnection: Omit<InvoiceConnection, 'edges' | 'nodes'> & { edges?: Maybe<Array<Maybe<ResolversParentTypes['InvoiceEdge']>>>, nodes?: Maybe<Array<Maybe<ResolversParentTypes['Invoice']>>> };
  InvoiceCreateForPackageInput: InvoiceCreateForPackageInput;
  InvoiceCreateForPackagePayload: Omit<InvoiceCreateForPackagePayload, 'invoice'> & { invoice?: Maybe<ResolversParentTypes['Invoice']> };
  InvoiceEdge: Omit<InvoiceEdge, 'node'> & { node?: Maybe<ResolversParentTypes['Invoice']> };
  Item: Omit<Item, 'area' | 'business' | 'displayedLocation' | 'landArea' | 'nearestLocations' | 'phones'> & { area: ResolversParentTypes['Area'], business?: Maybe<ResolversParentTypes['Business']>, displayedLocation?: Maybe<ResolversParentTypes['DisplayedLocation']>, landArea?: Maybe<ResolversParentTypes['Area']>, nearestLocations?: Maybe<Array<ResolversParentTypes['NearestLocation']>>, phones: Array<ResolversParentTypes['DisplayedPhone']> };
  ItemBalanceTransaction: Omit<ItemBalanceTransaction, 'source'> & { source?: Maybe<ResolversParentTypes['ItemBalanceTransactionSource']> };
  ItemBalanceTransactionConnection: Omit<ItemBalanceTransactionConnection, 'edges' | 'nodes'> & { edges?: Maybe<Array<Maybe<ResolversParentTypes['ItemBalanceTransactionEdge']>>>, nodes?: Maybe<Array<Maybe<ResolversParentTypes['ItemBalanceTransaction']>>> };
  ItemBalanceTransactionEdge: Omit<ItemBalanceTransactionEdge, 'node'> & { node?: Maybe<ResolversParentTypes['ItemBalanceTransaction']> };
  ItemBalanceTransactionSource: ResolversUnionTypes<ResolversParentTypes>['ItemBalanceTransactionSource'];
  ItemConnection: Omit<ItemConnection, 'edges' | 'nodes'> & { edges?: Maybe<Array<Maybe<ResolversParentTypes['ItemEdge']>>>, nodes?: Maybe<Array<Maybe<ResolversParentTypes['Item']>>> };
  ItemCreateAreaInput: ItemCreateAreaInput;
  ItemCreateContactInput: ItemCreateContactInput;
  ItemCreateFloorInput: ItemCreateFloorInput;
  ItemCreateInput: ItemCreateInput;
  ItemCreateLocationInput: ItemCreateLocationInput;
  ItemCreatePayload: Omit<ItemCreatePayload, 'item'> & { item?: Maybe<ResolversParentTypes['Item']> };
  ItemEdge: Omit<ItemEdge, 'node'> & { node?: Maybe<ResolversParentTypes['Item']> };
  ItemEdit: Omit<ItemEdit, 'edit' | 'item'> & { edit: ResolversParentTypes['Edit'], item: ResolversParentTypes['Item'] };
  ItemFilter: ItemFilter;
  ItemPayInput: ItemPayInput;
  ItemPayPayload: Omit<ItemPayPayload, 'item'> & { item?: Maybe<ResolversParentTypes['Item']> };
  ItemProlongInput: ItemProlongInput;
  ItemProlongPayload: Omit<ItemProlongPayload, 'item'> & { item?: Maybe<ResolversParentTypes['Item']> };
  ItemUpdateAreaInput: ItemUpdateAreaInput;
  ItemUpdateFloorInput: ItemUpdateFloorInput;
  ItemUpdateInput: ItemUpdateInput;
  ItemUpdatePayload: Omit<ItemUpdatePayload, 'item'> & { item?: Maybe<ResolversParentTypes['Item']> };
  Keyword: Keyword;
  KeywordFilter: KeywordFilter;
  Limit: Scalars['Limit']['output'];
  Location: Location;
  LocationFilter: LocationFilter;
  LocationGroup: LocationGroup;
  LocationGroupFilter: LocationGroupFilter;
  LocationGroupLocationFilter: LocationGroupLocationFilter;
  MaskedPhone: MaskedPhone;
  MetaTag: MetaTag;
  Mutation: {};
  NearestLocation: ResolversUnionTypes<ResolversParentTypes>['NearestLocation'];
  NotificationReadInput: NotificationReadInput;
  NotificationReadPayload: NotificationReadPayload;
  ObjectPrice: ObjectPrice;
  Package: Package;
  PackagePeriod: PackagePeriod;
  Page: Page;
  PageInfo: PageInfo;
  PaidService: PaidService;
  PaidServiceOption: PaidServiceOption;
  Path: Scalars['Path']['output'];
  Payment: Omit<Payment, 'item'> & { item?: Maybe<ResolversParentTypes['Item']> };
  PaymentConnection: Omit<PaymentConnection, 'edges' | 'nodes'> & { edges?: Maybe<Array<Maybe<ResolversParentTypes['PaymentEdge']>>>, nodes?: Maybe<Array<Maybe<ResolversParentTypes['Payment']>>> };
  PaymentCreateInput: PaymentCreateInput;
  PaymentCreatePayload: PaymentCreatePayload;
  PaymentEdge: Omit<PaymentEdge, 'node'> & { node?: Maybe<ResolversParentTypes['Payment']> };
  PaymentMethod: PaymentMethod;
  PersonalAccountTransaction: Omit<PersonalAccountTransaction, 'payment'> & { payment?: Maybe<ResolversParentTypes['Payment']> };
  PersonalAccountTransactionConnection: Omit<PersonalAccountTransactionConnection, 'edges' | 'nodes'> & { edges?: Maybe<Array<Maybe<ResolversParentTypes['PersonalAccountTransactionEdge']>>>, nodes?: Maybe<Array<Maybe<ResolversParentTypes['PersonalAccountTransaction']>>> };
  PersonalAccountTransactionEdge: Omit<PersonalAccountTransactionEdge, 'node'> & { node?: Maybe<ResolversParentTypes['PersonalAccountTransaction']> };
  Phone: Phone;
  Photo: Photo;
  PhotoLimit: Scalars['PhotoLimit']['output'];
  Price: Price;
  Profile: ResolversUnionTypes<ResolversParentTypes>['Profile'];
  ProfileChangePasswordInput: ProfileChangePasswordInput;
  ProfileChangePasswordPayload: Omit<ProfileChangePasswordPayload, 'profile'> & { profile?: Maybe<ResolversParentTypes['Profile']> };
  ProfileRequestPasswordResetInput: ProfileRequestPasswordResetInput;
  ProfileRequestPasswordResetPayload: ProfileRequestPasswordResetPayload;
  ProfileResetPasswordInput: ProfileResetPasswordInput;
  ProfileResetPasswordPayload: ProfileResetPasswordPayload;
  ProfileSignInInput: ProfileSignInInput;
  ProfileSignInPayload: Omit<ProfileSignInPayload, 'profile'> & { profile?: Maybe<ResolversParentTypes['Profile']> };
  ProfileSignOutPayload: Omit<ProfileSignOutPayload, 'profile'> & { profile?: Maybe<ResolversParentTypes['Profile']> };
  ProfileUpdateInput: ProfileUpdateInput;
  ProfileUpdatePayload: Omit<ProfileUpdatePayload, 'profile'> & { profile?: Maybe<ResolversParentTypes['Profile']> };
  Query: {};
  Report: Report;
  ReportCreateInput: ReportCreateInput;
  ReportCreatePayload: ReportCreatePayload;
  ReportReason: ReportReason;
  Residence: Residence;
  ResidenceConstructionProgress: ResidenceConstructionProgress;
  ResidenceInfrastructure: ResidenceInfrastructure;
  ResidenceSpecialOffer: ResidenceSpecialOffer;
  SEO: Seo;
  String: Scalars['String']['output'];
  TotalPrice: TotalPrice;
  URL: Scalars['URL']['output'];
  UserError: UserError;
};

export type OneOfDirectiveArgs = { };

export type OneOfDirectiveResolver<Result, Parent, ContextType = any, Args = OneOfDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AgencyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Agency'] = ResolversParentTypes['Agency']> = {
  contactAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coverUrl?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  itemCategories?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['Item']>, ParentType, ContextType, RequireFields<AgencyItemsArgs, 'limit' | 'sort'>>;
  itemsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  logoUrl?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<ResolversTypes['Path'], ParentType, ContextType>;
  phonesMasked?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  viewsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AgencyLimitScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AgencyLimit'], any> {
  name: 'AgencyLimit';
}

export type AnalyticLogCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnalyticLogCreatePayload'] = ResolversParentTypes['AnalyticLogCreatePayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApartmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Apartment'] = ResolversParentTypes['Apartment']> = {
  area?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  balconiesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bathroomsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mortgagePriceFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  photos?: Resolver<Maybe<Array<ResolversTypes['Photo']>>, ParentType, ContextType, Partial<ApartmentPhotosArgs>>;
  priceFrom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  repair?: Resolver<Maybe<ResolversTypes['BuildingProperty']>, ParentType, ContextType>;
  rooms?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Area'] = ResolversParentTypes['Area']> = {
  units?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AssetUrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AssetURL'], any> {
  name: 'AssetURL';
}

export type BaseCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseCompany'] = ResolversParentTypes['BaseCompany']> = {
  __resolveType: TypeResolveFn<'Company' | 'CompanyProfile', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  targetType?: Resolver<Maybe<ResolversTypes['CompanyTargetTypeEnum']>, ParentType, ContextType>;
};

export type BookmarkResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bookmark'] = ResolversParentTypes['Bookmark']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
  itemId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookmarkCreatePayload'] = ResolversParentTypes['BookmarkCreatePayload']> = {
  bookmark?: Resolver<Maybe<ResolversTypes['Bookmark']>, ParentType, ContextType>;
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookmarkDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookmarkDeletePayload'] = ResolversParentTypes['BookmarkDeletePayload']> = {
  bookmark?: Resolver<Maybe<ResolversTypes['Bookmark']>, ParentType, ContextType>;
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BreadcrumbResolvers<ContextType = any, ParentType extends ResolversParentTypes['Breadcrumb'] = ResolversParentTypes['Breadcrumb']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['Path']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuildingPropertyResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuildingProperty'] = ResolversParentTypes['BuildingProperty']> = {
  iconUrl?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BusinessResolvers<ContextType = any, ParentType extends ResolversParentTypes['Business'] = ResolversParentTypes['Business']> = {
  __resolveType: TypeResolveFn<'Agency' | 'Residence', ParentType, ContextType>;
};

export type CardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Card'] = ResolversParentTypes['Card']> = {
  expiry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isExpired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  issuer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pan?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  system?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CardCreatePayload'] = ResolversParentTypes['CardCreatePayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  redirectUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CardCreateStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CardDeletePayload'] = ResolversParentTypes['CardDeletePayload']> = {
  cards?: Resolver<Array<ResolversTypes['Card']>, ParentType, ContextType>;
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardSetPrimaryPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CardSetPrimaryPayload'] = ResolversParentTypes['CardSetPrimaryPayload']> = {
  cards?: Resolver<Array<ResolversTypes['Card']>, ParentType, ContextType>;
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CardUpdatePayload'] = ResolversParentTypes['CardUpdatePayload']> = {
  card?: Resolver<Maybe<ResolversTypes['Card']>, ParentType, ContextType>;
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CardUpdateStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']> = {
  areRoomsRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  areaUnits?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  children?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType>;
  hasBuildingType?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasFloor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasLandArea?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasRepair?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasRooms?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photosRequired?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pluralName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CityResolvers<ContextType = any, ParentType extends ResolversParentTypes['City'] = ResolversParentTypes['City']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  locations?: Resolver<Maybe<Array<ResolversTypes['Location']>>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<ResolversTypes['Path'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CityLimitScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['CityLimit'], any> {
  name: 'CityLimit';
}

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  targetType?: Resolver<Maybe<ResolversTypes['CompanyTargetTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyBalanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyBalance'] = ResolversParentTypes['CompanyBalance']> = {
  item?: Resolver<ResolversTypes['CompanyItemBalance'], ParentType, ContextType>;
  personalAccount?: Resolver<ResolversTypes['CompanyPersonalAccountBalance'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyItemBalanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyItemBalance'] = ResolversParentTypes['CompanyItemBalance']> = {
  free?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nextFreeAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  paid?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyNotification'] = ResolversParentTypes['CompanyNotification']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyPersonalAccountBalanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyPersonalAccountBalance'] = ResolversParentTypes['CompanyPersonalAccountBalance']> = {
  bonus?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cash?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pack?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyProfile'] = ResolversParentTypes['CompanyProfile']> = {
  alternativePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  balance?: Resolver<ResolversTypes['CompanyBalance'], ParentType, ContextType>;
  business?: Resolver<Maybe<ResolversTypes['Business']>, ParentType, ContextType>;
  contactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasUnpaidInvoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isBusiness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<ResolversTypes['ItemConnection'], ParentType, ContextType, RequireFields<CompanyProfileItemsArgs, 'state'>>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notifications?: Resolver<Array<ResolversTypes['CompanyNotification']>, ParentType, ContextType>;
  paidItemsBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  paidServices?: Resolver<Array<ResolversTypes['PaidService']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  targetType?: Resolver<Maybe<ResolversTypes['CompanyTargetTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Currency'] = ResolversParentTypes['Currency']> = {
  __resolveType: TypeResolveFn<'Apartment' | 'ESPrice' | 'ObjectPrice' | 'Price', ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DisplayedLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DisplayedLocation'] = ResolversParentTypes['DisplayedLocation']> = {
  __resolveType: TypeResolveFn<'City' | 'Location', ParentType, ContextType>;
};

export type DisplayedPhoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['DisplayedPhone'] = ResolversParentTypes['DisplayedPhone']> = {
  __resolveType: TypeResolveFn<'MaskedPhone' | 'Phone', ParentType, ContextType>;
};

export type EsAreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ESArea'] = ResolversParentTypes['ESArea']> = {
  units?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EsItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ESItem'] = ResolversParentTypes['ESItem']> = {
  area?: Resolver<ResolversTypes['ESArea'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  featured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  floors?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasBillOfSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasMortgage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasRepair?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isBusiness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  leased?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  paidDaily?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  path?: Resolver<ResolversTypes['Path'], ParentType, ContextType>;
  photos?: Resolver<Array<ResolversTypes['ESPhoto']>, ParentType, ContextType, Partial<EsItemPhotosArgs>>;
  photosCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['ESPrice'], ParentType, ContextType>;
  rooms?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  vipped?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EsItemConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ESItemConnection'] = ResolversParentTypes['ESItemConnection']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['ESItemEdge']>>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ESItem']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EsItemEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ESItemEdge'] = ResolversParentTypes['ESItemEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['ESItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EsPhotoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ESPhoto'] = ResolversParentTypes['ESPhoto']> = {
  f460x345?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  large?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EsPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ESPrice'] = ResolversParentTypes['ESPrice']> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditResolvers<ContextType = any, ParentType extends ResolversParentTypes['Edit'] = ResolversParentTypes['Edit']> = {
  area?: Resolver<Maybe<ResolversTypes['Area']>, ParentType, ContextType>;
  contactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  floors?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasBillOfSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasMortgage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasRepair?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  landArea?: Resolver<Maybe<ResolversTypes['Area']>, ParentType, ContextType>;
  paidDaily?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  photos?: Resolver<Maybe<Array<ResolversTypes['Photo']>>, ParentType, ContextType, Partial<EditPhotosArgs>>;
  price?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  rooms?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface EstablishmentLimitScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EstablishmentLimit'], any> {
  name: 'EstablishmentLimit';
}

export type FlashMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlashMessage'] = ResolversParentTypes['FlashMessage']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  additionalConditions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  package?: Resolver<Maybe<ResolversTypes['Package']>, ParentType, ContextType>;
  paidAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  paidServices?: Resolver<Array<ResolversTypes['PaidService']>, ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  printUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  startsAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  vatAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceConnection'] = ResolversParentTypes['InvoiceConnection']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['InvoiceEdge']>>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Invoice']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceCreateForPackagePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceCreateForPackagePayload'] = ResolversParentTypes['InvoiceCreateForPackagePayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  invoice?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceEdge'] = ResolversParentTypes['InvoiceEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['Item'] = ResolversParentTypes['Item']> = {
  actions?: Resolver<Array<ResolversTypes['ItemUserActionsEnum']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  area?: Resolver<ResolversTypes['Area'], ParentType, ContextType>;
  breadcrumbs?: Resolver<Maybe<Array<ResolversTypes['Breadcrumb']>>, ParentType, ContextType>;
  buildingTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  business?: Resolver<Maybe<ResolversTypes['Business']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['Category'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  contactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contactTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayedLocation?: Resolver<Maybe<ResolversTypes['DisplayedLocation']>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  featured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  floors?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasBillOfSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasMortgage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasRepair?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isBookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExpiredManually?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFeatured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isKapitalbankApplicable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLeased?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMine?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isReported?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVipped?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  landArea?: Resolver<Maybe<ResolversTypes['Area']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  leased?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metaTags?: Resolver<Array<ResolversTypes['MetaTag']>, ParentType, ContextType>;
  nearestLocations?: Resolver<Maybe<Array<ResolversTypes['NearestLocation']>>, ParentType, ContextType>;
  paidDaily?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  paidServices?: Resolver<Maybe<Array<ResolversTypes['PaidService']>>, ParentType, ContextType>;
  path?: Resolver<ResolversTypes['Path'], ParentType, ContextType>;
  phones?: Resolver<Array<ResolversTypes['DisplayedPhone']>, ParentType, ContextType>;
  photos?: Resolver<Maybe<Array<ResolversTypes['Photo']>>, ParentType, ContextType, Partial<ItemPhotosArgs>>;
  photosCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  rejectReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rooms?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['ItemStatusEnum'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  vipped?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemBalanceTransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemBalanceTransaction'] = ResolversParentTypes['ItemBalanceTransaction']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['ItemBalanceTransactionSource']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemBalanceTransactionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemBalanceTransactionConnection'] = ResolversParentTypes['ItemBalanceTransactionConnection']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemBalanceTransactionEdge']>>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemBalanceTransaction']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemBalanceTransactionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemBalanceTransactionEdge'] = ResolversParentTypes['ItemBalanceTransactionEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['ItemBalanceTransaction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemBalanceTransactionSourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemBalanceTransactionSource'] = ResolversParentTypes['ItemBalanceTransactionSource']> = {
  __resolveType: TypeResolveFn<'Item' | 'Payment', ParentType, ContextType>;
};

export type ItemConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemConnection'] = ResolversParentTypes['ItemConnection']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['ItemEdge']>>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Item']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemCreatePayload'] = ResolversParentTypes['ItemCreatePayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  item?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ItemCreateStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemEdge'] = ResolversParentTypes['ItemEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemEditResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemEdit'] = ResolversParentTypes['ItemEdit']> = {
  edit?: Resolver<ResolversTypes['Edit'], ParentType, ContextType>;
  item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemPayPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemPayPayload'] = ResolversParentTypes['ItemPayPayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  item?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemProlongPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemProlongPayload'] = ResolversParentTypes['ItemProlongPayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  item?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ItemUpdatePayload'] = ResolversParentTypes['ItemUpdatePayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  item?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType>;
  lockedUntil?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ItemUpdateStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeywordResolvers<ContextType = any, ParentType extends ResolversParentTypes['Keyword'] = ResolversParentTypes['Keyword']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  term?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LimitScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Limit'], any> {
  name: 'Limit';
}

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  locationGroup?: Resolver<ResolversTypes['LocationGroup'], ParentType, ContextType>;
  locationGroupId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  path?: Resolver<ResolversTypes['Path'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationGroup'] = ResolversParentTypes['LocationGroup']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  locations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, Partial<LocationGroupLocationsArgs>>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MaskedPhoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['MaskedPhone'] = ResolversParentTypes['MaskedPhone']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetaTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetaTag'] = ResolversParentTypes['MetaTag']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  changeProfilePassword?: Resolver<Maybe<ResolversTypes['ProfileChangePasswordPayload']>, ParentType, ContextType, RequireFields<MutationChangeProfilePasswordArgs, 'input'>>;
  createAnalyticLog?: Resolver<Maybe<ResolversTypes['AnalyticLogCreatePayload']>, ParentType, ContextType, RequireFields<MutationCreateAnalyticLogArgs, 'input'>>;
  createBookmark?: Resolver<Maybe<ResolversTypes['BookmarkCreatePayload']>, ParentType, ContextType, RequireFields<MutationCreateBookmarkArgs, 'itemId'>>;
  createCard?: Resolver<Maybe<ResolversTypes['CardCreatePayload']>, ParentType, ContextType>;
  createInvoiceForPackage?: Resolver<Maybe<ResolversTypes['InvoiceCreateForPackagePayload']>, ParentType, ContextType, RequireFields<MutationCreateInvoiceForPackageArgs, 'input'>>;
  createItem?: Resolver<Maybe<ResolversTypes['ItemCreatePayload']>, ParentType, ContextType, RequireFields<MutationCreateItemArgs, 'input'>>;
  createPayment?: Resolver<Maybe<ResolversTypes['PaymentCreatePayload']>, ParentType, ContextType, RequireFields<MutationCreatePaymentArgs, 'input'>>;
  createReport?: Resolver<Maybe<ResolversTypes['ReportCreatePayload']>, ParentType, ContextType, RequireFields<MutationCreateReportArgs, 'input'>>;
  deleteBookmark?: Resolver<Maybe<ResolversTypes['BookmarkDeletePayload']>, ParentType, ContextType, RequireFields<MutationDeleteBookmarkArgs, 'itemId'>>;
  deleteCard?: Resolver<Maybe<ResolversTypes['CardDeletePayload']>, ParentType, ContextType, RequireFields<MutationDeleteCardArgs, 'input'>>;
  payForItem?: Resolver<Maybe<ResolversTypes['ItemPayPayload']>, ParentType, ContextType, RequireFields<MutationPayForItemArgs, 'input'>>;
  prolongItem?: Resolver<Maybe<ResolversTypes['ItemProlongPayload']>, ParentType, ContextType, RequireFields<MutationProlongItemArgs, 'input'>>;
  readNotification?: Resolver<Maybe<ResolversTypes['NotificationReadPayload']>, ParentType, ContextType, RequireFields<MutationReadNotificationArgs, 'input'>>;
  requestPasswordReset?: Resolver<Maybe<ResolversTypes['ProfileRequestPasswordResetPayload']>, ParentType, ContextType, RequireFields<MutationRequestPasswordResetArgs, 'input'>>;
  resetPassword?: Resolver<Maybe<ResolversTypes['ProfileResetPasswordPayload']>, ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'input'>>;
  setPrimaryCard?: Resolver<Maybe<ResolversTypes['CardSetPrimaryPayload']>, ParentType, ContextType, RequireFields<MutationSetPrimaryCardArgs, 'input'>>;
  signIn?: Resolver<Maybe<ResolversTypes['ProfileSignInPayload']>, ParentType, ContextType, RequireFields<MutationSignInArgs, 'input'>>;
  signOut?: Resolver<Maybe<ResolversTypes['ProfileSignOutPayload']>, ParentType, ContextType>;
  updateCard?: Resolver<Maybe<ResolversTypes['CardUpdatePayload']>, ParentType, ContextType, RequireFields<MutationUpdateCardArgs, 'input'>>;
  updateItem?: Resolver<Maybe<ResolversTypes['ItemUpdatePayload']>, ParentType, ContextType, RequireFields<MutationUpdateItemArgs, 'input'>>;
  updateProfile?: Resolver<Maybe<ResolversTypes['ProfileUpdatePayload']>, ParentType, ContextType, RequireFields<MutationUpdateProfileArgs, 'input'>>;
};

export type NearestLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['NearestLocation'] = ResolversParentTypes['NearestLocation']> = {
  __resolveType: TypeResolveFn<'City' | 'Location', ParentType, ContextType>;
};

export type NotificationReadPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationReadPayload'] = ResolversParentTypes['NotificationReadPayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectPrice'] = ResolversParentTypes['ObjectPrice']> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  perUnit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['TotalPrice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Package'] = ResolversParentTypes['Package']> = {
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paidAdsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  periods?: Resolver<Array<ResolversTypes['PackagePeriod']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  promotions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  walletAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackagePeriodResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackagePeriod'] = ResolversParentTypes['PackagePeriod']> = {
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaidServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaidService'] = ResolversParentTypes['PaidService']> = {
  activeUntil?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['PaidServiceOption']>, ParentType, ContextType>;
  paymentMethods?: Resolver<Array<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  targetType?: Resolver<ResolversTypes['PaidServiceTargetTypeEnum'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaidServiceOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaidServiceOption'] = ResolversParentTypes['PaidServiceOption']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  priceWithDiscount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, RequireFields<PaidServiceOptionPriceWithDiscountArgs, 'paymentMethodId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PathScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Path'], any> {
  name: 'Path';
}

export type PaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  item?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType>;
  paidServiceOptionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMethodName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentConnection'] = ResolversParentTypes['PaymentConnection']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentEdge']>>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Payment']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentCreatePayload'] = ResolversParentTypes['PaymentCreatePayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  paymentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  redirectUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PaymentCreateStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentEdge'] = ResolversParentTypes['PaymentEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod']> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cardToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonalAccountTransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonalAccountTransaction'] = ResolversParentTypes['PersonalAccountTransaction']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonalAccountTransactionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonalAccountTransactionConnection'] = ResolversParentTypes['PersonalAccountTransactionConnection']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['PersonalAccountTransactionEdge']>>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['PersonalAccountTransaction']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonalAccountTransactionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonalAccountTransactionEdge'] = ResolversParentTypes['PersonalAccountTransactionEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['PersonalAccountTransaction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['Phone'] = ResolversParentTypes['Phone']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhotoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Photo'] = ResolversParentTypes['Photo']> = {
  f460x345?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  f660x496?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  full?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  large?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PhotoLimitScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PhotoLimit'], any> {
  name: 'PhotoLimit';
}

export type PriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price']> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['Profile'] = ResolversParentTypes['Profile']> = {
  __resolveType: TypeResolveFn<'CompanyProfile', ParentType, ContextType>;
};

export type ProfileChangePasswordPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileChangePasswordPayload'] = ResolversParentTypes['ProfileChangePasswordPayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ProfileChangePasswordStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileRequestPasswordResetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileRequestPasswordResetPayload'] = ResolversParentTypes['ProfileRequestPasswordResetPayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['RequestPasswordResetStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileResetPasswordPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileResetPasswordPayload'] = ResolversParentTypes['ProfileResetPasswordPayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ResetPasswordStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileSignInPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileSignInPayload'] = ResolversParentTypes['ProfileSignInPayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileSignOutPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileSignOutPayload'] = ResolversParentTypes['ProfileSignOutPayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileUpdatePayload'] = ResolversParentTypes['ProfileUpdatePayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ProfileUpdateStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  agencies?: Resolver<Array<ResolversTypes['Agency']>, ParentType, ContextType, RequireFields<QueryAgenciesArgs, 'limit'>>;
  agency?: Resolver<Maybe<ResolversTypes['Agency']>, ParentType, ContextType, Partial<QueryAgencyArgs>>;
  bookmarks?: Resolver<Array<ResolversTypes['Bookmark']>, ParentType, ContextType, RequireFields<QueryBookmarksArgs, 'limit'>>;
  card?: Resolver<Maybe<ResolversTypes['Card']>, ParentType, ContextType, RequireFields<QueryCardArgs, 'id'>>;
  cards?: Resolver<Maybe<Array<ResolversTypes['Card']>>, ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType, RequireFields<QueryCategoriesArgs, 'filter' | 'limit'>>;
  cities?: Resolver<Array<ResolversTypes['City']>, ParentType, ContextType, RequireFields<QueryCitiesArgs, 'filter' | 'limit'>>;
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType, RequireFields<QueryCityArgs, 'id'>>;
  flashMessages?: Resolver<Array<ResolversTypes['FlashMessage']>, ParentType, ContextType>;
  invoice?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<QueryInvoiceArgs, 'id'>>;
  invoices?: Resolver<ResolversTypes['InvoiceConnection'], ParentType, ContextType, Partial<QueryInvoicesArgs>>;
  item?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType, RequireFields<QueryItemArgs, 'id'>>;
  itemBalanceHistory?: Resolver<ResolversTypes['ItemBalanceTransactionConnection'], ParentType, ContextType, Partial<QueryItemBalanceHistoryArgs>>;
  itemEdit?: Resolver<Maybe<ResolversTypes['ItemEdit']>, ParentType, ContextType, RequireFields<QueryItemEditArgs, 'itemId'>>;
  items?: Resolver<Array<ResolversTypes['ESItem']>, ParentType, ContextType, RequireFields<QueryItemsArgs, 'limit' | 'offset' | 'sort'>>;
  itemsConnection?: Resolver<ResolversTypes['ESItemConnection'], ParentType, ContextType, RequireFields<QueryItemsConnectionArgs, 'sort'>>;
  itemsPublishedTodayCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  keywords?: Resolver<Array<ResolversTypes['Keyword']>, ParentType, ContextType, RequireFields<QueryKeywordsArgs, 'limit'>>;
  landmarks?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QueryLandmarksArgs, 'cityId' | 'latitude' | 'longitude'>>;
  locationGroups?: Resolver<Array<ResolversTypes['LocationGroup']>, ParentType, ContextType, RequireFields<QueryLocationGroupsArgs, 'limit'>>;
  locations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QueryLocationsArgs, 'filter' | 'limit'>>;
  packages?: Resolver<Array<ResolversTypes['Package']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType, RequireFields<QueryPageArgs, 'slug'>>;
  paidServiceOptions?: Resolver<Array<ResolversTypes['PaidServiceOption']>, ParentType, ContextType, RequireFields<QueryPaidServiceOptionsArgs, 'paidServiceId'>>;
  paidServices?: Resolver<Array<ResolversTypes['PaidService']>, ParentType, ContextType, RequireFields<QueryPaidServicesArgs, 'targetIds' | 'targetType'>>;
  paymentHistory?: Resolver<ResolversTypes['PaymentConnection'], ParentType, ContextType, Partial<QueryPaymentHistoryArgs>>;
  paymentMethods?: Resolver<Array<ResolversTypes['PaymentMethod']>, ParentType, ContextType, RequireFields<QueryPaymentMethodsArgs, 'paidServiceId'>>;
  personalAccountBalanceHistory?: Resolver<ResolversTypes['PersonalAccountTransactionConnection'], ParentType, ContextType, Partial<QueryPersonalAccountBalanceHistoryArgs>>;
  profile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  regions?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QueryRegionsArgs, 'cityId'>>;
  reportReasons?: Resolver<Maybe<Array<ResolversTypes['ReportReason']>>, ParentType, ContextType>;
  residence?: Resolver<Maybe<ResolversTypes['Residence']>, ParentType, ContextType, Partial<QueryResidenceArgs>>;
  residences?: Resolver<Array<ResolversTypes['Residence']>, ParentType, ContextType, RequireFields<QueryResidencesArgs, 'limit'>>;
  seo?: Resolver<Maybe<ResolversTypes['SEO']>, ParentType, ContextType, RequireFields<QuerySeoArgs, 'path'>>;
  townships?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QueryTownshipsArgs, 'regionId'>>;
};

export type ReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportCreatePayload'] = ResolversParentTypes['ReportCreatePayload']> = {
  errors?: Resolver<Array<ResolversTypes['UserError']>, ParentType, ContextType>;
  report?: Resolver<Maybe<ResolversTypes['Report']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportReasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportReason'] = ResolversParentTypes['ReportReason']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResidenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Residence'] = ResolversParentTypes['Residence']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apartmentRooms?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  apartments?: Resolver<Maybe<Array<ResolversTypes['Apartment']>>, ParentType, ContextType>;
  apartmentsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  apartmentsPerFloor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blocks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  briefInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  brochureUrl?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  buildingClass?: Resolver<Maybe<ResolversTypes['BuildingProperty']>, ParentType, ContextType>;
  buildingsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ceilingHeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>;
  companyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  constructionProgresses?: Resolver<Array<ResolversTypes['ResidenceConstructionProgress']>, ParentType, ContextType>;
  contactEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coverUrl?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  deadline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  decoration?: Resolver<Maybe<ResolversTypes['BuildingProperty']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  facilities?: Resolver<Array<ResolversTypes['BuildingProperty']>, ParentType, ContextType>;
  floorsPerBuilding?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasBillOfSale?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasCollageGallery?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  infrastructures?: Resolver<Array<ResolversTypes['ResidenceInfrastructure']>, ParentType, ContextType>;
  itemRooms?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  itemsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  layout?: Resolver<Maybe<ResolversTypes['BuildingProperty']>, ParentType, ContextType>;
  liftsPerBlock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  locations?: Resolver<Maybe<Array<ResolversTypes['Location']>>, ParentType, ContextType>;
  logoUrl?: Resolver<Maybe<ResolversTypes['AssetURL']>, ParentType, ContextType>;
  metroName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<ResolversTypes['Path'], ParentType, ContextType>;
  phonesMasked?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  photos?: Resolver<Maybe<Array<ResolversTypes['Photo']>>, ParentType, ContextType, Partial<ResidencePhotosArgs>>;
  price?: Resolver<ResolversTypes['ObjectPrice'], ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  showBrandHeader?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  specialOffers?: Resolver<Array<ResolversTypes['ResidenceSpecialOffer']>, ParentType, ContextType>;
  technology?: Resolver<Maybe<ResolversTypes['BuildingProperty']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  windowType?: Resolver<Maybe<ResolversTypes['BuildingProperty']>, ParentType, ContextType>;
  youtubeVideoId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResidenceConstructionProgressResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResidenceConstructionProgress'] = ResolversParentTypes['ResidenceConstructionProgress']> = {
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  isPromo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photos?: Resolver<Maybe<Array<ResolversTypes['Photo']>>, ParentType, ContextType, Partial<ResidenceConstructionProgressPhotosArgs>>;
  youtubeVideoId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResidenceInfrastructureResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResidenceInfrastructure'] = ResolversParentTypes['ResidenceInfrastructure']> = {
  distance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  infrastructure?: Resolver<Maybe<ResolversTypes['BuildingProperty']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResidenceSpecialOfferResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResidenceSpecialOffer'] = ResolversParentTypes['ResidenceSpecialOffer']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offerType?: Resolver<ResolversTypes['ResidenceSpecialOfferTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeoResolvers<ContextType = any, ParentType extends ResolversParentTypes['SEO'] = ResolversParentTypes['SEO']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  h1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TotalPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['TotalPrice'] = ResolversParentTypes['TotalPrice']> = {
  maximum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minimum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export type UserErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserError'] = ResolversParentTypes['UserError']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Agency?: AgencyResolvers<ContextType>;
  AgencyLimit?: GraphQLScalarType;
  AnalyticLogCreatePayload?: AnalyticLogCreatePayloadResolvers<ContextType>;
  Apartment?: ApartmentResolvers<ContextType>;
  Area?: AreaResolvers<ContextType>;
  AssetURL?: GraphQLScalarType;
  BaseCompany?: BaseCompanyResolvers<ContextType>;
  Bookmark?: BookmarkResolvers<ContextType>;
  BookmarkCreatePayload?: BookmarkCreatePayloadResolvers<ContextType>;
  BookmarkDeletePayload?: BookmarkDeletePayloadResolvers<ContextType>;
  Breadcrumb?: BreadcrumbResolvers<ContextType>;
  BuildingProperty?: BuildingPropertyResolvers<ContextType>;
  Business?: BusinessResolvers<ContextType>;
  Card?: CardResolvers<ContextType>;
  CardCreatePayload?: CardCreatePayloadResolvers<ContextType>;
  CardDeletePayload?: CardDeletePayloadResolvers<ContextType>;
  CardSetPrimaryPayload?: CardSetPrimaryPayloadResolvers<ContextType>;
  CardUpdatePayload?: CardUpdatePayloadResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  City?: CityResolvers<ContextType>;
  CityLimit?: GraphQLScalarType;
  Company?: CompanyResolvers<ContextType>;
  CompanyBalance?: CompanyBalanceResolvers<ContextType>;
  CompanyItemBalance?: CompanyItemBalanceResolvers<ContextType>;
  CompanyNotification?: CompanyNotificationResolvers<ContextType>;
  CompanyPersonalAccountBalance?: CompanyPersonalAccountBalanceResolvers<ContextType>;
  CompanyProfile?: CompanyProfileResolvers<ContextType>;
  Currency?: CurrencyResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DisplayedLocation?: DisplayedLocationResolvers<ContextType>;
  DisplayedPhone?: DisplayedPhoneResolvers<ContextType>;
  ESArea?: EsAreaResolvers<ContextType>;
  ESItem?: EsItemResolvers<ContextType>;
  ESItemConnection?: EsItemConnectionResolvers<ContextType>;
  ESItemEdge?: EsItemEdgeResolvers<ContextType>;
  ESPhoto?: EsPhotoResolvers<ContextType>;
  ESPrice?: EsPriceResolvers<ContextType>;
  Edit?: EditResolvers<ContextType>;
  EstablishmentLimit?: GraphQLScalarType;
  FlashMessage?: FlashMessageResolvers<ContextType>;
  Invoice?: InvoiceResolvers<ContextType>;
  InvoiceConnection?: InvoiceConnectionResolvers<ContextType>;
  InvoiceCreateForPackagePayload?: InvoiceCreateForPackagePayloadResolvers<ContextType>;
  InvoiceEdge?: InvoiceEdgeResolvers<ContextType>;
  Item?: ItemResolvers<ContextType>;
  ItemBalanceTransaction?: ItemBalanceTransactionResolvers<ContextType>;
  ItemBalanceTransactionConnection?: ItemBalanceTransactionConnectionResolvers<ContextType>;
  ItemBalanceTransactionEdge?: ItemBalanceTransactionEdgeResolvers<ContextType>;
  ItemBalanceTransactionSource?: ItemBalanceTransactionSourceResolvers<ContextType>;
  ItemConnection?: ItemConnectionResolvers<ContextType>;
  ItemCreatePayload?: ItemCreatePayloadResolvers<ContextType>;
  ItemEdge?: ItemEdgeResolvers<ContextType>;
  ItemEdit?: ItemEditResolvers<ContextType>;
  ItemPayPayload?: ItemPayPayloadResolvers<ContextType>;
  ItemProlongPayload?: ItemProlongPayloadResolvers<ContextType>;
  ItemUpdatePayload?: ItemUpdatePayloadResolvers<ContextType>;
  Keyword?: KeywordResolvers<ContextType>;
  Limit?: GraphQLScalarType;
  Location?: LocationResolvers<ContextType>;
  LocationGroup?: LocationGroupResolvers<ContextType>;
  MaskedPhone?: MaskedPhoneResolvers<ContextType>;
  MetaTag?: MetaTagResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NearestLocation?: NearestLocationResolvers<ContextType>;
  NotificationReadPayload?: NotificationReadPayloadResolvers<ContextType>;
  ObjectPrice?: ObjectPriceResolvers<ContextType>;
  Package?: PackageResolvers<ContextType>;
  PackagePeriod?: PackagePeriodResolvers<ContextType>;
  Page?: PageResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PaidService?: PaidServiceResolvers<ContextType>;
  PaidServiceOption?: PaidServiceOptionResolvers<ContextType>;
  Path?: GraphQLScalarType;
  Payment?: PaymentResolvers<ContextType>;
  PaymentConnection?: PaymentConnectionResolvers<ContextType>;
  PaymentCreatePayload?: PaymentCreatePayloadResolvers<ContextType>;
  PaymentEdge?: PaymentEdgeResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  PersonalAccountTransaction?: PersonalAccountTransactionResolvers<ContextType>;
  PersonalAccountTransactionConnection?: PersonalAccountTransactionConnectionResolvers<ContextType>;
  PersonalAccountTransactionEdge?: PersonalAccountTransactionEdgeResolvers<ContextType>;
  Phone?: PhoneResolvers<ContextType>;
  Photo?: PhotoResolvers<ContextType>;
  PhotoLimit?: GraphQLScalarType;
  Price?: PriceResolvers<ContextType>;
  Profile?: ProfileResolvers<ContextType>;
  ProfileChangePasswordPayload?: ProfileChangePasswordPayloadResolvers<ContextType>;
  ProfileRequestPasswordResetPayload?: ProfileRequestPasswordResetPayloadResolvers<ContextType>;
  ProfileResetPasswordPayload?: ProfileResetPasswordPayloadResolvers<ContextType>;
  ProfileSignInPayload?: ProfileSignInPayloadResolvers<ContextType>;
  ProfileSignOutPayload?: ProfileSignOutPayloadResolvers<ContextType>;
  ProfileUpdatePayload?: ProfileUpdatePayloadResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Report?: ReportResolvers<ContextType>;
  ReportCreatePayload?: ReportCreatePayloadResolvers<ContextType>;
  ReportReason?: ReportReasonResolvers<ContextType>;
  Residence?: ResidenceResolvers<ContextType>;
  ResidenceConstructionProgress?: ResidenceConstructionProgressResolvers<ContextType>;
  ResidenceInfrastructure?: ResidenceInfrastructureResolvers<ContextType>;
  ResidenceSpecialOffer?: ResidenceSpecialOfferResolvers<ContextType>;
  SEO?: SeoResolvers<ContextType>;
  TotalPrice?: TotalPriceResolvers<ContextType>;
  URL?: GraphQLScalarType;
  UserError?: UserErrorResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  oneOf?: OneOfDirectiveResolver<any, any, ContextType>;
};
