import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CATEGORY_ROOMS } from '../../../../../utils/helpers/constants';
import { Chips } from '../../../../ui/Chips';
import { chipsStyles, RoomsContainer } from './styled';
import { ModalFooter } from '../../../../ui/Modal/ModalFooter';
import { actionButtonStyles } from '../../styled';

interface RoomsCountProps {
  hide: () => void;
}

export const RoomsCount = ({ hide }: RoomsCountProps) => {
  const { t } = useTranslation();
  const { change, submit } = useForm();
  const { values } = useFormState();
  const [roomIds, setRoomIds] = useState<string[]>(values.room_ids || []);

  const onChange = (value: string) => {
    setRoomIds(prevRoomIds => {
      const updatedRoomIds = new Set(prevRoomIds);
      if (updatedRoomIds.has(value)) {
        updatedRoomIds.delete(value);
      } else {
        updatedRoomIds.add(value);
      }
      return Array.from(updatedRoomIds);
    });
  };

  const onReset = () => {
    setRoomIds([]);
  };

  const onSubmit = () => {
    change('room_ids', roomIds);
    submit();
    hide();
  };

  return (
    <>
      <RoomsContainer>
        {CATEGORY_ROOMS.map(room => (
          <Chips
            text={room}
            value={room}
            type="button"
            hasCustomOnChange
            key={`room-${room}`}
            storedValue={roomIds || []}
            cypress={`new-search-rooms-${room}`}
            onClick={() => onChange(room)}
            styles={chipsStyles}
          />
        ))}
      </RoomsContainer>
      <ModalFooter
        resetIsDisabled={!roomIds.length}
        onReset={onReset}
        onConfirm={onSubmit}
        resetText={t('common.reset')}
        confirmText={t('common.apply')}
        confirmCypress="apply-btn"
        styles={actionButtonStyles}
      />
    </>
  );
};
